import { render, staticRenderFns } from "./StatisticalCharts.vue?vue&type=template&id=4d8d7d02&scoped=true"
import script from "./StatisticalCharts.vue?vue&type=script&lang=js"
export * from "./StatisticalCharts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8d7d02",
  null
  
)

/* custom blocks */
import block0 from "@/lang/global/global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5ChomeView%5CStatisticalCharts.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/lang/home/charts.json?vue&type=custom&index=1&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5ChomeView%5CStatisticalCharts.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports