import Vue from "vue";
import PrimeVue from 'primevue/config';
import Ripple from "primevue/ripple";
import Tooltip from 'primevue/tooltip';
import { lang } from "./lang";
const locale = localStorage.getItem('locale') || 'en';
Vue.use(PrimeVue, {
  locale: lang[locale],
  ripple: true,
  ToastService: true,
  tooltip: true
});
Vue.directive("ripple", Ripple);
Vue.directive('tooltip', Tooltip);
// so i can use ripple.directive in my own components as below
/* 
  <div class="p-ripple purple" v-ripple></div>
  .p-ripple.purple .p-ink {
      background: rgba(256,39,176,.3);
  }
*/

///////////////////// ToastService ///////////////////////////////////////
import ToastService from 'primevue/toastservice';
Vue.use(ToastService);
import Toast from 'primevue/toast';
Vue.component('Toast', Toast);
import ConfirmationService from 'primevue/confirmationservice';
Vue.use(ConfirmationService);
//////////////////// BadgeDirective //////////////////////////////////////////////
import BadgeDirective from 'primevue/badgedirective';
Vue.directive("badge", BadgeDirective);
//////////////// IMPORT PRIMEVUE COMPONENTS //////////////////////////////
import Button from 'primevue/button';
import TabMenu from 'primevue/tabmenu';
import Dropdown from 'primevue/dropdown';
import Chart from "primevue/chart";
import Paginator from 'primevue/paginator';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Password from 'primevue/password';
import AutoComplete from "primevue/autocomplete";
import Checkbox from "primevue/checkbox";
import FileUpload from 'primevue/fileupload';
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import ProgressSpinner from 'primevue/progressspinner';
import Galleria from 'primevue/galleria';
import Message from 'primevue/message';
import Menu from 'primevue/menu';
import ConfirmPopup from 'primevue/confirmpopup';
import OverlayPanel from 'primevue/overlaypanel';
import Carousel from 'primevue/carousel';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import Skeleton from "primevue/skeleton";
import SelectButton from 'primevue/selectbutton';





Vue.component('Dropdown', Dropdown);
Vue.component('Carousel', Carousel);
Vue.component('Chart', Chart);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Button', Button);
Vue.component('TabMenu', TabMenu);
Vue.component('Paginator', Paginator);
Vue.component('InputText', InputText);
Vue.component('Textarea', Textarea);
Vue.component('Password', Password);
Vue.component('Checkbox', Checkbox);
Vue.component('FileUpload', FileUpload);
Vue.component('Avatar', Avatar);
Vue.component('Badge', Badge);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('Galleria', Galleria);
Vue.component('Message', Message);
Vue.component('Menu', Menu);
Vue.component('ConfirmPopup', ConfirmPopup);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('Carousel', Carousel);
Vue.component('Dialog', Dialog);
Vue.component('Calendar', Calendar);
Vue.component('InputNumber', InputNumber);
Vue.component('Skeleton', Skeleton);
Vue.component('SelectButton', SelectButton);


//////////////////////////////////////////////////////////////////////////


//////////////////////////// primevue css /////////////////////////////////////////
import "primevue/resources/primevue.min.css"; //core css
import "primevue/resources/themes/saga-green/theme.css"; //theme
import "primeicons/primeicons.css"; //icons
import './customTheme.css';  //my-theme
/////////////////////////////////////////////////////////////////////