import axios from 'axios'
export default {
  namespaced: true,
  state: {
    serverLoading: false,
    serverResponse: null,
    serverError: false,
    ////////////////////
    detailsServerLoading: false,
    detailsServerResponse: null,
    detailsServerError: false,
    ////////////////////
    orderStatusLoading: false,
    orderStatusResponse: null,
    orderStatusError: false,
  },
  // get the currant state value
  getters: { 
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
    /////////////////////////////////////////////
    getDetailsServerLoading: state => state.detailsServerLoading,
    getDetailsServerResponse: state => state.detailsServerResponse,
    getDetailsServerError: state => state.detailsServerError,
    /////////////////////////////////////////////
    getOrderStatusLoading: state => state.orderStatusLoading,
    getOrderStatusResponse: state => state.orderStatusResponse,
    getOrderStatusError: state => state.orderStatusError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    },
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    ///////////////////////////////////
    SET_Details_SERVER_LOADING(state, loading) {
      state.detailsServerLoading = loading;
    },
    SET_Details_SERVER_RESPONSE(state, response) {
      state.detailsServerResponse = response;
    },
    SET_Details_SERVER_ERROR(state, error) {
      state.detailsServerError = error;
    },
    ///////////////////////////////////
    SET_Order_Status_LOADING(state, loading) {
      state.orderStatusLoading = loading;
    },
    SET_Order_Status_RESPONSE(state, response) {
      state.orderStatusResponse = response;
    },
    SET_Order_Status_ERROR(state, error) {
      state.orderStatusError = error;
    },
    ///////////////////////////////////
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadOrders({ commit },payload) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.get('orders/offers',{params: payload});
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', error.response.data.errors);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
    async loadOrderDetails({ commit },payload) {
      try {
        commit('SET_Details_SERVER_LOADING', true);
        const response = await axios.get(`orders/offers/show/${payload.orderId}`);
        commit('SET_Details_SERVER_RESPONSE', response.data);
        commit('SET_Details_SERVER_LOADING', false);
        commit('SET_Details_SERVER_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_Details_SERVER_ERROR', error.response.data.errors);
        commit('SET_Details_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
    async changeOrderStatus({ commit },payload) {
      try {
        commit('SET_Order_Status_LOADING', true);
        const response = await axios.get(`orders/offers/show/${payload.orderId}/status/${payload.status}`);
        commit('SET_Order_Status_RESPONSE', response.data);
        commit('SET_Order_Status_LOADING', false);
        commit('SET_Order_Status_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_Order_Status_ERROR', error.response.data.errors);
        commit('SET_Order_Status_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
  },
};
