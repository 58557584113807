import axios from 'axios'
export default {
  namespaced: true,
  state: {
    form: {
      sale_type:"normal",
      name:["",""],
      type_fish_id: null,
      market_id: null,
      details:["",""],
      city_id: null,
      size: "",
      quality: "",
      category:"",
      fish_type:"",
      quantity: null,
      price: null,
      // weight: null,
      // image:null,
    },
    // form: {
    //   sale_type:"normal",
    //   name:["الاسم","name"],
    //   type_fish_id: 1,
    //   market_id: 15,
    //   details:["اكتب الوصف","test test description"],
    //   city_id: 5,
    //   size: "small",
    //   quality: "A",
    //   category:"fish",
    //   fish_type:"natural",
    //   quantity: 50,
    //   weight: 100,
    //   price: 23,
    //   image:null,
    // },
    serverResponse: null,
    serverError: false,
    serverLoading: false
  },
  // get the currant state value
  getters: { 
    getForm: state => state.form,
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_FORM_DATA(state, formData) {
      state.form = formData;
    },
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    },
    RESET(state){
      state.serverResponse= null;
      state.serverError= false;
      state.serverLoading= false;
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    setForm({ commit }){
      commit('SET_FORM_DATA',form);
    },
    resetForm({ commit }){
        const form = {
          sale_type:"normal",
          name:["",""],
          type_fish_id: null,
          market_id: null,
          details:["",""],
          city_id: null,
          size: "",
          quality: "",
          category:"",
          fish_type:"",
          quantity: null,
          price: null,
          // weight: null,
          // image:null,
        }
      // const form = {
      //   sale_type:"normal",
      //   name:["الاسم","name"],
      //   type_fish_id: 1,
      //   market_id: 15,
      //   details:["اكتب الوصف","test test description"],
      //   city_id: 5,
      //   size: "small",
      //   quality: "A",
      //   category:"fish",
      //   fish_type:"natural",
      //   quantity: 50,
      //   weight: 100,
      //   price: 23,
      //   image:null,
      // }
      commit('SET_FORM_DATA',form);
    },
    reset({ commit }){
      commit('RESET');
    },
    async create({ commit }, formData) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.post('product/create', formData);
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', error.response.data.errors);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
  },
};
