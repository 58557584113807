import axios from 'axios'
export default {
  namespaced: true,
  state: {
    search: null,
    searchLoading: false,
    searchError: false,
  },
  // get the currant state value
  getters: { 
    searchData: state => state.search,
    searchLoading: state => state.searchLoading,
    searchError: state => state.searchError,

  },
  // use to perform un mutate or change states
  mutations: {
    setSearch (state, data) {
      state.search = data
    },
    setSearchLoading (state, loading) {
      state.searchLoading = loading
    },
    setSearchError (state, error) {
      state.searchError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadSearch ({ commit },payload) {
      commit('setSearchLoading', true)
      try {
        const response = await axios.get('products',{ params: payload })
        // console.log(response)
        commit('setSearch', response.data)
        commit('setSearchLoading', false)
        commit('setSearchError', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setSearchLoading', false)
        commit('setSearchError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
  },
};
