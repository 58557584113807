<template>
  <div class="header">
    <div class="container">
        <section>
          <div class="first-nave py-2 d-flex justify-content-between align-items-center flex-wrap d-none d-lg-flex " >
            <div class="logo">
              <router-link to="/">
                <img v-if="currentRouteName=='home'" :src="mediaURL+info.logo4" alt="company logo" class="w-100 h-100">
                <img v-else :src="mediaURL+info.logo2" alt="company logo" class="w-100 h-100">
              </router-link>
            </div>
            <div class="locale-registration d-flex d-flex justify-content-between align-items-center flex-wrap gap-3 my-2">
              <div v-if="user&&isAuthenticated" class="is-authenticated d-flex align-items-center gap-4">
                
                <Avatar class="user-avatar" shape="circle" size="large" v-if="user.avatar">
                  <img :src="mediaURL+'user/'+user.avatar" alt=""  @click="navigateToProfilePage">
                </Avatar>

                <Avatar class="user-avatar" shape="circle" size="large" v-else>
                  <span @click="navigateToProfilePage" class="fs-6">
                    {{ user.f_name[0].toUpperCase()+ '.'+ user.l_name[0].toUpperCase() }}
                  </span>
                </Avatar>
                <i 
                  class="pi pi-shopping-cart p-text-secondary notification-icon" 
                  style="font-size: 1.5rem" 
                  aria-haspopup="true" 
                  aria-controls="overlay_menu"
                  @click="toggleCart" 
                  v-badge="cart.length>0?cart.length:''" 
                  :class="{'badge-dotted':!cart.length>0}"
                  >
                </i>
                <OverlayPanel ref="cartOP">
                  <Cart  v-if="cart.length" :items="cart" />
                  <div v-else class="text-center">
                    <small>{{ $t('emptyCartMsg') }}</small>
                  </div>
                </OverlayPanel>                
                <i  
                  class="pi pi-bell p-text-secondary notification-icon" 
                  style="font-size: 1.5rem" 
                  aria-haspopup="true" 
                  aria-controls="overlay_menu"
                  @click="toggleNotifications" 
                  v-badge="notifications.length>0?notifications.length:''" 
                  :class="{'badge-dotted':!notifications.length>0}"
                  >
                </i>
                <OverlayPanel ref="notificationsOP">
                  <div class="notifications-wrapper" v-if="notifications.length">
                    <div class="notification d-flex align-items-center" v-for="notification in notifications" 
                      :key="notification.id"  @click="openNotification(notification)">

                      <div class="notification-icon">
                        <Avatar class="user-av" shape="circle" size="large" v-if="notification.data.user.avatar">
                          <img :src="mediaURL+'user/'+notification.data.user.avatar" alt="">
                        </Avatar>
  
                        <Avatar class="user-av" shape="circle" size="large" v-else>
                          <span><i class="pi pi-user fs-5"></i></span>
                          <!-- <span class="fs-6">
                            {{ 
                              notification.data.user.name[0].toUpperCase()
                              + '.'+ 
                              notification.data.user.r_name[0].toUpperCase() 
                            }}
                          </span> -->
                        </Avatar>
                      </div>
                      <div class="notification-content px-2 flex-grow-1">
                        <small class="notification-user d-block mb-2 fw-bold">{{ notification.data.user.name }}</small>
                        <h6 class="notification-message">{{ notification.data.message }}</h6>
                        <small class="notification-user d-block text-muted">
                          {{ new Date (notification.data.time).toLocaleTimeString() }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <small>{{ $t('noNotificationsMsg') }}</small>
                  </div>
                </OverlayPanel>
              </div>
              <div v-else class="registration">
                <Button :label="$t('signIn')" class="px-4 p-button-secondary p-button-sm" 
                @click.prevent="navigateToSignInPage" />
              </div>
              <div class="locale d-flex align-items-center flex-wrap gap-3 my-2">
                <div>
                  <Button 
                    label="En" 
                    class="p-button-sm"
                    :class="[
                      locale=='en'?'p-button-outlined':'p-button-text',
                      currentRouteName=='home'?'':'p-button-secondary'
                    ]" 
                    @click="changeLocaleTOEn" 
                  />
                </div>
                <div>
                  <Button 
                    class="p-button-sm"
                    label="Ar" 
                    :class="[
                      locale=='ar'?'p-button-outlined':'p-button-text',
                      currentRouteName=='home'?'':'p-button-secondary'
                    ]"  
                    @click="changeLocaleTOAr" 
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <nav class="navbar navbar-expand-lg" 
        :class="[currentRouteName=='home'?'navbar-expand-lg-dark':'navbar-expand-lg-light']" >
          <div class="container-fluid px-0">
            <div v-if="user&&isAuthenticated" class="is-authenticated d-flex align-items-center gap-4 d-lg-none d-block">
              <Avatar class="user-avatar" shape="circle" size="large" v-if="user.avatar">
                <img :src="mediaURL+'user/'+user.avatar" alt=""  @click="navigateToProfilePage">
              </Avatar>

              <Avatar class="user-avatar" shape="circle" size="large" v-else>
                <span @click="navigateToProfilePage" class="fs-6">
                  {{ user.f_name[0].toUpperCase()+ '.'+ user.l_name[0].toUpperCase() }}
                </span>
              </Avatar>
                <i 
                  class="pi pi-shopping-cart p-text-secondary notification-icon" 
                  style="font-size: 1.5rem" 
                  aria-haspopup="true" 
                  aria-controls="overlay_menu"
                  @click="toggleSmallCart" 
                  v-badge="cart.length>0?cart.length:''" 
                  :class="{'badge-dotted':!cart.length>0}"
                  >
                </i>
                <OverlayPanel ref="smallCartOP">
                  <Cart  v-if="cart.length" :items="cart" />
                  <div v-else class="text-center">
                    <small>{{ $t('emptyCartMsg') }}</small>
                  </div>
                </OverlayPanel>               
              <i
                class="pi pi-bell p-text-secondary notification-icon" 
                style="font-size: 1.5rem" 
                aria-haspopup="true" 
                aria-controls="overlay_menu"
                @click="toggleSmallNotifications" 
                v-badge="notifications.length>0?notifications.length:''" 
                :class="{'badge-dotted':!notifications.length>0}"
                >
              </i>
              <OverlayPanel ref="smallNotificationsOP">
                <div class="notifications-wrapper" v-if="notifications.length">
                  <div class="notification d-flex align-items-center" v-for="notification in notifications" 
                    :key="notification.id"  @click="openNotification(notification)">

                    <div class="notification-icon">
                      <Avatar class="user-av" shape="circle" size="large" v-if="notification.data.user.avatar">
                        <img :src="mediaURL+'user/'+notification.data.user.avatar" alt="">
                      </Avatar>

                      <Avatar class="user-av" shape="circle" size="large" v-else>
                        <span><i class="pi pi-user fs-5"></i></span>
                        <!-- <span class="fs-6">
                          {{ 
                            notification.data.user.name[0].toUpperCase()
                            + '.'+ 
                            notification.data.user.r_name[0].toUpperCase() 
                          }}
                        </span> -->
                      </Avatar>
                    </div>
                    <div class="notification-content px-2 flex-grow-1">
                      <small class="notification-user d-block mb-2 fw-bold">{{ notification.data.user.name }}</small>
                      <h6 class="notification-message">{{ notification.data.message }}</h6>
                      <small class="notification-user d-block text-muted">
                        {{ new Date (notification.data.time).toLocaleTimeString() }}
                      </small>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  <small>{{ $t('noNotificationsMsg') }}</small>
                </div>
              </OverlayPanel>
            </div>
            <router-link to="/" class="navbar-brand d-lg-none d-block m-0 fs-1" v-else>
                <div >
                    <img v-if="currentRouteName=='home'" :src="mediaURL+info.logo4" alt="company logo" width="auto" height="45">
                    <img v-else :src="mediaURL+info.logo2" alt="company logo" width="auto" height="45" >
                </div>
            </router-link>
            <!-- <a class="navbar-brand d-lg-none d-block m-0 fs-1" href="#">
              <img v-if="currentRouteName=='home'" :src="mediaURL+info.logo3" alt="logo" width="50" height="50">
              <img v-else :src="mediaURL+info.logo" alt="logo" width="50" height="50">
            </a> -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span :class="[currentRouteName=='home'?'white-icon':'secondary-icon']"><i class="pi pi-bars fs-5"></i></span>
            </button>
            <div class="collapse navbar-collapse align-items-center justify-content-between flex-wrap"  
             id="navbarSupportedContent">
              <ul class="navbar-nav my-3 p-0">
                <li class="nav-item" v-for="item in navList" :key="item.id">
                  <router-link 
                    class="nav-link" 
                    :class="[currentRouteName=='home'?'white-nav-link':'secondary-nav-link']"  
                    :to="{'name':item.link}"
                    @click.native="hideNavMenu"
                  >
                  {{ item[locale] }}
                </router-link>
                </li>
                <li class="nav-item d-lg-none d-block" v-if="!user&&!isAuthenticated">
                  <router-link 
                    class="nav-link" 
                    :class="[currentRouteName=='home'?'white-nav-link':'secondary-nav-link']"  
                    :to="{'name': 'signIn'}"
                    @click.native="hideNavMenu"
                  >
                  {{$t('signIn')}}
                </router-link>
                </li>
                <div class="locale d-flex align-items-center flex-wrap gap-3 my-2 d-lg-none d-flex">
                  <li>
                    <Button 
                      label="En" 
                      class="p-button-sm"
                      :class="[
                        locale=='en'?'p-button-outlined':'p-button-text',
                        currentRouteName=='home'?'':'p-button-secondary'
                      ]" 
                      @click="changeLocaleTOEn" 
                    />
                  </li>
                  <li>
                    <Button 
                      class="p-button-sm"
                      label="Ar" 
                      :class="[
                        locale=='ar'?'p-button-outlined':'p-button-text',
                        currentRouteName=='home'?'':'p-button-secondary'
                      ]"  
                      @click="changeLocaleTOAr" 
                    />
                  </li>
              </div>
              </ul>
              <form class="search-box">
                <AutoComplete v-model="selectedOption" :suggestions="customizedProducts" @complete="search($event)" field="name" @item-select="navigateToProductDetails($event)" :placeholder="$t('search')" :class="[currentRouteName=='home'?'search-field-home':'search-field']" />
              </form>
            </div>
          </div>
        </nav>
    </div>
  </div>
</template>
<i18n src="@/lang/layout/header.json"></i18n>
<script>
// @ is an alias to /src
import Cart from '@/components/cart/Cart';
export default {
  name: 'Header',
  components: {
    Cart
  },
  data() {
    return {
      navList: [
        { en: 'Home', ar: 'الرئيسية', link: 'home', id: 1 },
        { en: 'Local Market', ar: 'سوق محلي', link: 'localMarket', id: 2 },
        {
          en: 'International Market',
          ar: 'سوق دولي',
          link: 'internationalMarket',
          id: 3
        },
        {
          en: 'Special Products',
          ar: 'المنتجات المميزة',
          link: 'specialProducts',
          id: 4
        },
        { en: 'Auctions', ar: 'المزادات', link: 'auctions', id: 5 },
        // {en:"Requests",ar:"الطلبات",link:"requests",id:6},
        { en: 'News', ar: 'الأخبار', link: 'news', id: 7 },
        { en: 'About Us', ar: 'من نحن', link: 'aboutUs', id: 8 },
        { en: 'Contact Us', ar: 'اتصل بنا', link: 'contactUs', id: 9 }
      ],
      selectedOption: null,
      suggestions: [],
      searchOptions: []
      ////////////////////////
    };
  },
  computed: {
    mediaURL() {
      return this.$store.getters['urls/mediaURL'];
    },
    mainStaticData() {
      return this.$store.getters['mainStaticData/mainStaticData'];
    },
    info() {
      if (this.mainStaticData) return this.mainStaticData.data.info;
      else return false;
    },
    currentRouteName() {
      return this.$route.name;
    },
    isAuthenticated() {
      return this.$store.getters['userStatus/isAuthenticated'];
    },
    user() {
      return this.$store.getters['userStatus/getUser'];
    },
    //////////////// Notifications //////////////////
    isLoading() {
      return this.$store.getters['notifications/getServerLoading'];
    },
    validResponse() {
      return this.$store.getters['notifications/getServerResponse'];
    },
    notificationsData() {
      if (this.validResponse) {
        return this.validResponse.data;
      }
      return [];
    },
    notifications() {
      if (this.notificationsData.length) {
        return this.notificationsData;
      }
      return [];
    },
    error() {
      return this.$store.getters['notifications/getServerError'];
    },
    errors() {
      if (this.error) {
        return Object.values(this.error);
      }
      return [];
    },
    //////////////// Notifications //////////////////
    cart() {
      return this.$store.getters['cart/cart'];
    },
    //////////////// search //////////////////
    searchLoading() {
      return this.$store.getters['search/searchLoading'];
    },
    searchData() {
      return this.$store.getters['search/searchData'];
    },
    searchProducts() {
      if (this.searchData) return this.searchData['data']['products']['data'];
      else return [];
    },
    customizedProducts() {
      if (this.searchProducts) {
        let customized = [];
        if (this.locale == 'ar') {
          this.searchProducts.forEach(product => {
            const name =
              product.translations[0]['name'] +
              ' ' +
              '( ' +
              product.translations[1]['name'] +
              ' )';
            customized.push({
              name,
              id: product.id,
              sale_type: product.sale_type
            });
          });
        } else if (this.locale == 'en') {
          this.searchProducts.forEach(product => {
            const name =
              product.translations[1]['name'] +
              ' ' +
              '( ' +
              product.translations[0]['name'] +
              ' )';
            customized.push({
              name,
              id: product.id,
              sale_type: product.sale_type
            });
          });
        }
        return customized;
      } else return [];
    },
    filterParams() {
      return {
        s: this.selectedOption
      };
    }
  },
  mounted() {
    ////////////// Notifications //////////////////
    if (this.isAuthenticated) {
      this.loadNotificationsData();
    }
    ////////////// Notifications //////////////////
  },
  methods: {
    //////////////////////////
    hideNavMenu() {
      // toggle the visibility of the navbar menu
      const navbarCollapse = document.querySelector('.navbar-collapse');
      if (navbarCollapse.classList.contains('show')) {
        navbarCollapse.classList.remove('show');
      }
    },
    //////////////////////////
    changeLocaleTOEn() {
      if (this.locale == 'ar') {
        // this.$i18n.locale = 'en'
        localStorage.setItem('locale', 'en');
        this.$router.go(0);
      }
    },
    changeLocaleTOAr() {
      if (this.locale == 'en') {
        // this.$i18n.locale = 'ar'
        localStorage.setItem('locale', 'ar');
        this.$router.go(0);
      }
    },
    navigateToSignInPage() {
      if (this.currentRouteName != 'signIn') {
        this.$router.push({ name: 'signIn' });
      }
    },
    navigateToProfilePage() {
      // console.log('navigateToProfilePage')
      if (this.currentRouteName != 'account') {
        this.$router.push({ name: 'account' });
      }
    },
    async search(event) {
      // console.log(event.query)
      if (!event.query.trim().length) {
        // console.log(this.selectedOption.trim().length)
        this.suggestions = [...this.searchOptions];
      } else {
        try {
          await this.$store
            .dispatch('search/loadSearch', this.filterParams)
            .then(res => {
              // console.log(res)
            });
        } catch (error) {
          console.error(error);
        }
        // this.suggestions = this.searchOptions.filter((opt) => {
        //     return opt.en.toLowerCase().startsWith(event.query.toLowerCase());
        // });
        // this.suggestions = this.searchOptions.filter((opt) => {
        //     return opt.en.toLowerCase().startsWith(event.query.toLowerCase());
        // });
      }
    },
    navigateToProductDetails(event) {
      // console.log(event.value)
      const value = event.value;
      if (value.sale_type == 'normal') {
        this.$router.push({
          name: 'productDetails',
          params: { productId: value.id }
        });
      } else if (value.sale_type == 'auction') {
        this.$router.push({
          name: 'auctionDetails',
          params: { auctionId: value.id }
        });
      }
    },
    async loadNotificationsData() {
      try {
        await this.$store
          .dispatch('notifications/loadNotifications')
          .then(res => {
            // console.log(res)
          });
        // Do something with the loaded data
      } catch (error) {
        // console.log(error)
      }
    },
    toggleNotifications(event) {
      this.$refs.notificationsOP.toggle(event);
    },
    toggleSmallNotifications(event) {
      this.$refs.smallNotificationsOP.toggle(event);
    },
    openNotification(notification) {
      if (
        notification.data.data_type === 'orders' ||
        notification.data.data_type === 'auction_orders'
      ) {
        if (this.$route.name != 'sellOrders') {
          this.$router.push({ name: 'sellOrders' });
        }
        // if(this.$route.name!='orderDetails'&&this.$route.params.orderId!=notification.data.data_id){
        //   this.$router.push({name:'orderDetails',params:{orderId:notification.data.data_id}});
        // }
      } else if (notification.data.data_type === 'user_orders') {
        if (this.$route.name != 'purchaseOrders') {
          this.$router.push({ name: 'purchaseOrders' });
        }
      } else if (notification.data.data_type === 'auction') {
        if (this.$route.name != 'myAuctions') {
          this.$router.push({ name: 'myAuctions' });
        }
      } else if (notification.data.data_type === 'auction_confirm') {
        // else if(notification.data.data_type==='auction'){
        if (this.$route.name != 'auctionConfirmation') {
          this.$router.push({
            name: 'auctionConfirmation',
            params: { auctionId: notification.data.data_id }
          });
        }
      } else if (notification.data.data_type === 'request_offer') {
        if (this.$route.name != 'myRequests') {
          this.$router.push({ name: 'myRequests' });
        }
      }
    },
    toggleCart(event) {
      this.$refs.cartOP.toggle(event);
    },
    toggleSmallCart(event) {
      this.$refs.smallCartOP.toggle(event);
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 10;
  .first-nave {
    .logo {
      height: 5rem;
      img {
      }
    }
  }

  // .navbar-expand-lg{
  //   position:relative;
  //   // background-color: rgba(0,0,0,0.75) !important;
  //   padding: 0.5rem;
  //   border-radius: 0.5rem 0.5rem 0rem 0rem;
  // }
  // .navbar-collapse.collapse{
  //   // background-color: rgba(0,0,0,0.75);
  //   position: absolute;
  //   top:70px;
  //   width: 100%;
  //   left:0px;
  //   right:0px;
  //   padding:1rem;
  //   z-index: 100;
  //   border-radius: 0 0 0.5rem 0.5rem;
  //   transition: padding 0.5s ease-in-out;
  //   .search-box{
  //     width:100%;
  //     /deep/.p-autocomplete-input{
  //       // background-color: white !important;
  //     }
  //   }
  // }
  // .navbar-collapse.collapse.show{
  //   background-color: rgba(0,0,0,0.75);
  // }
  // .navbar-expand-lg-dark:has(.navbar-collapse.collapse.show){
  //   background-color: rgba(0,0,0,0.75) !important;
  //   .navbar-collapse.collapse.show{
  //     background-color: rgba(0,0,0,0.75);
  //     .search-box{
  //     /deep/.p-autocomplete-input{
  //       background-color: white !important;
  //       color: $secondary !important;
  //     }
  //   }
  //   }
  // }
  // .navbar-expand-lg-light:has(.navbar-collapse.collapse.show){
  //   background-color: rgba($gray,1) !important;
  //   .navbar-collapse.collapse.show{
  //     background-color: rgba($gray,1);
  //     .search-box{
  //     /deep/.p-autocomplete-input{
  //       background-color: white !important;
  //     }
  //   }
  //   }
  // }

  .navbar {
    background: transparent;

    .white-icon,
    .navbar-toggler {
      color: white;
    }
    .secondary-icon,
    .navbar-toggler {
      color: $secondary;
    }
    .navbar-nav {
      .nav-item {
        .white-nav-link {
          color: white;
          &:hover {
            color: $primary;
          }
        }
        .secondary-nav-link {
          color: $secondary;
          &:hover {
            color: $primary;
          }
        }
        .nav-link.router-link-exact-active.router-link-active {
          font-weight: bold;
          border-bottom: 3px solid;
          width: max-content;
          transition: border-color 0.75s linear;
        }
        .white-nav-link.router-link-exact-active.router-link-active {
          color: $primary;
          border-color: $primary;
        }
        .secondary-nav-link.router-link-exact-active.router-link-active {
          color: $secondary;
          border-color: $secondary;
        }
      }
    }
    .search-box {
      width: 280px;
    }
  }
  .notifications-wrapper {
    max-height: 400px;
    overflow-y: auto;
    padding: 0.5rem;
  }
  .notification {
    background-color: $gray;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: rgba($primary, 0.25);
    }
  }
  .notification-icon {
    cursor: pointer;
  }
  .notification-content {
    .notification-user {
      text-transform: capitalize;
    }
  }
}
:deep(.search-field-home) {
  width: 100%;
  .p-inputtext {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    outline: none;
    border: none;
    width: 100%;
  }
}
:deep(.user-avatar) {
  cursor: pointer;
}
:deep(.badge-dotted) {
  .p-badge {
    min-width: 4px;
    width: 8px !important;
    height: 8px !important;
    border-radius: 4px;
    padding: 0px !important;
  }
}
:deep(.search-field) {
  width: 100%;
  .p-inputtext {
    background-color: rgba($secondary, 0.15);
    color: $secondary;
    outline: none;
    border: none;
    width: 100%;
  }
}
:deep(.p-overlaypanel) {
  top: 60px !important;
  z-index: 99999 !important;
}
// @media screen and (max-width:992px) {
//   .header {
//     height: 70px ;
//     .navbar-collapse.collapse{
//     // background-color: rgba(0,0,0,0.75);
//     position: absolute;
//     top:70px;
//     width: 100%;
//     left:0px;
//     right:0px;
//     padding:1rem;
//     z-index: 100;
//     border-radius: 0 0 0.5rem 0.5rem;
//     transition: padding 0.5s ease-in-out;
//     .search-box{
//       width:100%;
//       /deep/.p-autocomplete-input{
//         // background-color: white !important;
//       }
//     }
//     }
//     .navbar-collapse.collapse.show{
//       background-color: rgba(0,0,0,0.75);
//     }
//   }
// }

@media (max-width: 991.98px) {
  .header {
    .container {
      // padding: 0;
      // max-width: 100% !important;
    }
  }
  .navbar {
    height: 92px;
    padding: 0 1rem;
    .search-box {
      width: 230px;
      :deep(.p-autocomplete-input) {
        background-color: white !important;
        color: $secondary !important;
      }
    }
    .navbar-brand {
      img {
        max-width: 190px;
      }
    }
  }
  .navbar-expand-lg-dark {
    background-color: rgba(0, 0, 0, 0.75) !important;
    .navbar-collapse {
      background-color: rgba(0, 0, 0, 0.75) !important;
    }
  }
  .navbar-expand-lg-light {
    background-color: rgba($gray, 1) !important;
    .navbar-collapse {
      background-color: rgba($gray, 1) !important;
    }
  }
  .navbar-collapse {
    position: absolute;
    top: 92px;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    padding: 0rem 2rem; /* adjust as needed to avoid overlapping the navbar */
    padding-bottom: 3rem;
    border-radius: 0 0 0.5rem 0.5rem;
    overflow-y: auto;
  }
  .navbar-collapse {
    /* other styles */
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  .navbar-collapse.show {
    opacity: 1;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    // justify-content: center;
    // align-items: center;
    // height: 100%;
  }
  .navbar-nav li {
    margin-bottom: 20px;
  }
  // .navbar-toggler {
  //   position: fixed;
  //   top: 10px;
  //   right: 10px;
  //   z-index: 10000;
  // }
}
</style>
