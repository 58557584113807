<template>
  <div class="home-view">
    <div class="overlay-bg">
      <div class="bg-container">
        <div class="bg-fade"></div>
        <div class="bg-fade"></div>
        <div class="bg-fade"></div>
        <div class="bg-fade"></div>
        <div class="bg-fade"></div>
        <div class="bg-fade"></div>
      </div>
    </div>
    <div class="container">
      <NewsTicker></NewsTicker>
      <Intro></Intro>
    </div>
    <div class="container-fluid p-0 m-0" v-if="fishList.length>0">
      <fishList></fishList>
    </div>
    <div class="container">
      <SpecialProducts></SpecialProducts>
      <SalesTrafficBrief></SalesTrafficBrief>
      <BestSeller></BestSeller>
      <StatisticalCharts></StatisticalCharts>
      <News></News>
    </div>
  </div>
</template>
<script>
import Intro from "@/components/homeView/Intro.vue"
import fishList from "@/components/homeView/fishList.vue"
import SpecialProducts from "@/components/homeView/SpecialProducts.vue"
import SalesTrafficBrief from "@/components/homeView/SalesTrafficBrief.vue"
import BestSeller from "@/components/homeView/BestSeller.vue"
import StatisticalCharts from "@/components/homeView/StatisticalCharts.vue"
import News from "@/components/homeView/News.vue"
import NewsTicker  from "@/components/global/utilities/NewsTicker.vue"
// @ is an alias to /src
export default {
  name: "HomeView",
  components: {
    Intro,
    fishList,
    SpecialProducts,
    SalesTrafficBrief,
    BestSeller,
    StatisticalCharts,
    News,
    NewsTicker,
  },
  data(){
    return {

    }
  },
  computed:{
    mainStaticData () {
        return this.$store.getters['mainStaticData/mainStaticData']
    },
    fishList () {
      if(this.mainStaticData)
        return this.$store.getters['mainStaticData/mainStaticData']["data"]["fish"]
      else return false
    },
  },
};
</script>
<style lang="scss" scoped>
.home-view {
  overflow: hidden;
  .overlay-bg{
    width: 100%;
    // height: 100%;
    min-height: 50rem;
    height:85vh;
    position: absolute;
    background: rgba(#000000,0.5);
    top: 0;
    left: 0;
    right: 0;
    // z-index:5;
    .bg-container{
      overflow: hidden;
      position: relative;
      width: 100%;
      min-height: 50rem;
      height:85vh;
    }
    .bg-fade{
        background-image: 
        url('@/assets/home/home-bg.png');
        position: absolute; 
        width: 100%;
        height: 100%; 
        top: 0;
        left: 0;
        right: 0;
        inset: 0;
        z-index:-1;
        background-size: cover; 
        background-position: center center;
        opacity: 0;
        animation-name: fade-zoom;
        animation-duration: 24s;
        animation-iteration-count: infinite;
    }
  }
  .bg-fade:nth-child(2){
      background-image:url('@/assets/home/slider/beautiful-fishes-swimming-sea-water.jpg');
      animation-delay: 0s;
  }
  .bg-fade:nth-child(3){
    background-image:url('@/assets/home/slider/blue-transparent-water-sea-near-coast-view-water-rocks-with-moss-fishes.jpg');
    animation-delay: 6s;
  }
  .bg-fade:nth-child(4){
    background-image:url('@/assets/home/slider/closeup-beautiful-exotic-colorful-little-fish.jpg');
    animation-delay: 12s;
  }
  .bg-fade:nth-child(5){
    background-image:url('@/assets/home/slider/closeup-shot-redhump-eartheater-fish-swimming-water.jpg');
    animation-delay: 18s;
  }
  .bg-fade:nth-child(6){
    background-image:url('@/assets/home/slider/coral-fishes-underwater-scene.jpg');
    animation-delay: 24s;
  }
}
@keyframes fade-zoom {
  0% {
    opacity:0;
    transform: scale(1);
  }
  16.66% {
      opacity:1
  }
  33.33% {
      opacity:1
  }
  48.66% {
      opacity:0
  }
  100% {
      opacity:0; 
      transform: scale(1.1);
  }
}
</style>
