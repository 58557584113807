import axios from 'axios'
export default {
  namespaced: true,
  state: {
    auctions: null,
    auctionsLoading: false,
    auctionsError: false,
    ////////////////////////////////
    auctionDetails: null,
    auctionDetailsLoading: false,
    auctionDetailsError: false,
    ////////////////////////////////
    bid: null,
    bidLoading: false,
    bidError: false,
  },
  // get the currant state value
  getters: { 
    auctionsData: state => state.auctions,
    auctionsLoading: state => state.auctionsLoading,
    auctionsError: state => state.auctionsError,
    /////////////////////////////////////////////
    auctionDetailsData: state => state.auctionDetails,
    auctionDetailsLoading: state => state.auctionDetailsLoading,
    auctionDetailsError: state => state.auctionDetailsError,
    /////////////////////////////////////////////
    bidData: state => state.bid,
    bidLoading: state => state.bidLoading,
    bidError: state => state.bidError,

  },
  // use to perform un mutate or change states
  mutations: {
    setAuctions (state, data) {
      state.auctions = data
    },
    setAuctionsLoading (state, loading) {
      state.auctionsLoading = loading
    },
    setAuctionsError (state, error) {
      state.auctionsError = error
    },
    //////////////////////////////////////////
    setAuctionDetails (state, data) {
      state.auctionDetails = data
    },
    setAuctionDetailsLoading (state, loading) {
      state.auctionDetailsLoading = loading
    },
    setAuctionDetailsError (state, error) {
      state.auctionDetailsError = error
    },
    //////////////////////////////////////////
    setBid (state, data) {
      state.bid = data
    },
    setBidLoading (state, loading) {
      state.bidLoading = loading
    },
    setBidError (state, error) {
      state.bidError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadAuctions ({ commit },payload) {
      commit('setAuctionsLoading', true)
      try {
        const response = await axios.get('auction/local',{params: payload})
        // console.log(response)
        commit('setAuctions', response.data)
        commit('setAuctionsLoading', false)
        commit('setAuctionsLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setAuctionsLoading', false)
        commit('setAuctionsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async loadAuctionDetails({ commit }, payload) {
      try {
        commit('setAuctionDetailsLoading', true)
        const response = await axios.get(`auction/show/${payload.id}`);
        commit('setAuctionDetails', response.data)
        commit('setAuctionDetailsLoading', false)
        commit('setAuctionDetailsError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setAuctionDetailsLoading', false)
        commit('setAuctionDetailsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async sendBid ({ commit },payload) {
      commit('setBidLoading', true)
      try {
        const response = await axios.post(`auction/show/${payload.id}/bid`,payload.money)
        // console.log(response)
        commit('setBid', response.data)
        commit('setBidLoading', false)
        commit('setBidError', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setBidLoading', false)
        commit('setBidError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
  },
};
