import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
if(!localStorage.getItem("locale"))localStorage.setItem("locale",'ar')
const i18n = new VueI18n({
  locale: localStorage.getItem("locale")
  // messages: {},
});
if (i18n.locale == "ar") {
  document.body.setAttribute('dir', 'rtl')
  document.body.setAttribute('lang', 'ar')
  document.body.setAttribute('class', 'rtl-content p-rtl')
  document.body.setAttribute('style', 'font-family:Tajawal,Segoe UI, Tahoma, Geneva, Verdana, sans-serif')
}
else {
  document.body.setAttribute('dir', 'ltr')
  document.body.setAttribute('lang', 'en')
  document.body.setAttribute('class', 'ltr-content p-ltr')
  document.body.setAttribute('style', 'font-family:Lato,Roboto, Helvetica, sans-serif')
}
export { i18n };