import axios from 'axios'
export default {
  namespaced: true,
  state: {
    trafficData: null,
    trafficDataLoading: false,
    trafficDataError: false
  },
  // get the currant state value
  getters: { 
    trafficData: state => state.trafficData,
    trafficDataLoading: state => state.trafficDataLoading,
    trafficDataError: state => state.trafficDataError
  },
  // use to perform un mutate or change states
  mutations: {
    setTrafficData (state, data) {
      state.trafficData = data
    },
    setTrafficDataLoading (state, loading) {
      state.trafficDataLoading = loading
    },
    setTrafficDataError (state, error) {
      state.trafficDataError = error
    }
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadTrafficData ({ commit },payload) {
      commit('setTrafficDataLoading', true)
      try {
        const response = await axios.get('main/fish_traffic',{params: payload})
        commit('setTrafficData', response.data)
        commit('setTrafficDataLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setTrafficDataError',true)
        commit('setTrafficDataLoading', false)
        return Promise.reject(error.response.data)
      }
    }
  },
};
