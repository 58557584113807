import { render, staticRenderFns } from "./Cart.vue?vue&type=template&id=2c624c60"
import script from "./Cart.vue?vue&type=script&lang=js"
export * from "./Cart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/global/global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5Ccart%5CCart.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports