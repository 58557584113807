<template>
  <!-- <div class="news-ticker" v-if="this.fishes.length>0">
    <ul ref="ticker" class="ticker-list">
      <li v-for="fish in fishes" :key="fish.id" class="my-2">
        <div class="text-center item-wrapper">
          <span class="d-block price">{{ fish.average_price }}</span>
          <router-link :to="{name:'localMarket', query:{fishId:fish.id} }" class="nav-link text-muted">
              <span class="d-block">{{ fish.name }}</span>
          </router-link>
        </div>
      </li>
    </ul>
  </div> -->

  <div class="d-flex justify-content-between align-items-center breaking-news">
    <marquee behavior="scroll" :direction="locale=='ar'?'left':'right'" onmouseover="this.stop();"
      onmouseout="this.start();"> 
        <div class="d-flex fish-list">
          <span class="fish-wrapper" v-for="fish in fishes" :key="fish.id">
            <span class="d-block price text-center">
              {{ fish.average_price }} 
              <small class="currency">{{ $t('currency') }}</small>
            </span>
            <router-link :to="{name:'localMarket', query:{fishId:fish.id} }" class="nav-link">
                <span class="d-block name">{{ fish.name }}</span>
            </router-link>
          </span>
        </div>
    </marquee>
  </div>
</template>
<i18n src="@/lang/global/global.json"></i18n>
<script>

export default {
  props: {
    fishes: {
      type: Array,
      required: true,
      default() {
        return []
      },
    }
  },
  mounted() {
    // if (this.fishes.length > 0)
      // this.startTicker()
  },
  methods: {
    // rtl only
    // startTicker() {
    //   const ticker = this.$refs.ticker
    //   const items = ticker.querySelectorAll('li')
    //   const itemWidth = items[0].offsetWidth
    //   const screenWidth = window.innerWidth
    //   let currentPosition = 0
    //   let currentItem = 0

    //   // Set initial position of ticker items
    //   items.forEach(item => {
    //     item.style.transform = `translateX(${currentPosition}px)`
    //     currentPosition += itemWidth
    //   })

    //   // Animate ticker items
    //   setInterval(() => {
    //     currentPosition -= 1
    //     items.forEach(item => {
    //       item.style.transform = `translateX(${currentPosition}px)`
    //     })
    //     if (currentPosition < -itemWidth) {
    //       const firstItem = ticker.querySelector('li:first-child')
    //       ticker.appendChild(firstItem)
    //       currentPosition += itemWidth
    //       currentItem++
    //       if (currentItem === items.length) {
    //         // Reset position of ticker when all items have been displayed
    //         currentPosition = 0
    //         currentItem = 0
    //         items.forEach(item => {
    //           item.style.transform = `translateX(${currentPosition}px)`
    //           currentPosition += itemWidth
    //         })
    //       }
    //     }
    //   }, 20)
    // },

    // both Dir
    // startTicker() {
    //   const ticker = this.$refs.ticker
    //   const items = ticker.querySelectorAll('li')
    //   const itemWidth = items[0].offsetWidth
    //   const screenWidth = window.innerWidth
    //   let currentPosition = 0
    //   let currentItem = 0
    //   if(this.locale=='ar'){
    //     // Set initial position of ticker items
    //     items.forEach(item => {
    //       item.style.transform = `translateX(${currentPosition}px)`
    //       currentPosition += itemWidth
    //     })

    //     // Animate ticker items
    //     setInterval(() => {
    //       currentPosition -= 1
    //       items.forEach(item => {
    //         item.style.transform = `translateX(${currentPosition}px)`
    //       })
    //       if (currentPosition < -itemWidth) {
    //         const firstItem = ticker.querySelector('li:first-child')
    //         ticker.appendChild(firstItem)
    //         currentPosition += itemWidth
    //         currentItem++
    //         if (currentItem === items.length) {
    //           // Reset position of ticker when all items have been displayed
    //           currentPosition = 0
    //           currentItem = 0
    //           items.forEach(item => {
    //             item.style.transform = `translateX(${currentPosition}px)`
    //             currentPosition += itemWidth
    //           })
    //         }
    //       }
    //     }, 20)
    //   }
    //   else {
    //       // Reverse the order of the ticker items
    //       const reversedItems = [...items].reverse()
    //       ticker.innerHTML = ''
    //       reversedItems.forEach(item => {
    //         ticker.appendChild(item)
    //       })

    //       // Set initial position of ticker items
    //       items.forEach(item => {
    //         item.style.transform = `translateX(${currentPosition}px)`
    //         currentPosition -= itemWidth
    //       })
    //       // Animate ticker items
    //       setInterval(() => {
    //         currentPosition += 1 // Change the direction of the animation
    //         items.forEach(item => {
    //           item.style.transform = `translateX(${currentPosition}px)`
    //         })
    //         if (currentPosition > itemWidth) { // Change the condition for resetting the ticker
    //           const lastItem = ticker.querySelector('li:last-child')
    //           ticker.insertBefore(lastItem, ticker.firstChild)
    //           currentPosition -= itemWidth
    //           currentItem++
    //           if (currentItem === items.length) {
    //             // Reset position of ticker when all items have been displayed
    //             currentPosition = 0
    //             currentItem = 0
    //             items.forEach(item => {
    //               item.style.transform = `translateX(${currentPosition}px)`
    //               currentPosition += itemWidth
    //             })
    //           }
    //         }
    //       }, 20)
    //   }
    // },


    // startTicker() {
    //   const ticker = this.$refs.ticker
    //   const items = ticker.querySelectorAll('li')
    //   const itemWidth = items[0].offsetWidth
    //   const screenWidth = window.innerWidth
    //   let currentPosition = 0
    //   let currentItem = 0
    //   let animationTimeoutId = null // add a variable to keep track of the animation timeout ID
    //   if (this.locale == 'ar') {
    //     // Set initial position of ticker items
    //     items.forEach(item => {
    //       item.style.transform = `translateX(${currentPosition}px)`
    //       currentPosition += itemWidth
    //     })

    //     // Animate ticker items
    //     const animate = () => {
    //       currentPosition -= 1
    //       items.forEach(item => {
    //         item.style.transform = `translateX(${currentPosition}px)`
    //       })
    //       if (currentPosition < -itemWidth) {
    //         const firstItem = ticker.querySelector('li:first-child')
    //         ticker.appendChild(firstItem)
    //         currentPosition += itemWidth
    //         currentItem++
    //         if (currentItem === items.length) {
    //           // Reset position of ticker when all items have been displayed
    //           currentPosition = 0
    //           currentItem = 0
    //           items.forEach(item => {
    //             item.style.transform = `translateX(${currentPosition}px)`
    //             currentPosition += itemWidth
    //           })
    //         }
    //       }
    //       // Set a new timeout for the next animation frame
    //       animationTimeoutId = setTimeout(animate, 20)
    //     }

    //     // Start the animation loop
    //     animate()
    //   } else {
    //     // Reverse the order of the ticker items
    //     const reversedItems = [...items].reverse()
    //     ticker.innerHTML = ''
    //     reversedItems.forEach(item => {
    //       ticker.appendChild(item)
    //     })

    //     // Set initial position of ticker items
    //     items.forEach(item => {
    //       item.style.transform = `translateX(${currentPosition}px)`
    //       currentPosition -= itemWidth
    //     })

    //     // Animate ticker items
    //     const animate = () => {
    //       currentPosition += 1 // Change the direction of the animation
    //       items.forEach(item => {
    //         item.style.transform = `translateX(${currentPosition}px)`
    //       })
    //       if (currentPosition > itemWidth) { // Change the condition for resetting the ticker
    //         const lastItem = ticker.querySelector('li:last-child')
    //         ticker.insertBefore(lastItem, ticker.firstChild)
    //         currentPosition -= itemWidth
    //         currentItem++
    //         if (currentItem === items.length) {
    //           // Reset position of ticker when all items have been displayed
    //           currentPosition = 0
    //           currentItem = 0
    //           items.forEach(item => {
    //             item.style.transform = `translateX(${currentPosition}px)`
    //             currentPosition += itemWidth
    //           })
    //         }
    //       }
    //       // Set a new timeout for the next animation frame
    //       animationTimeoutId = setTimeout(animate, 20)
    //     }

    //     // Start the animation loop
    //     animate()
    //   }

    //   // Stop the animation loop when the component is destroyed
    //   this.$once('hook:beforeDestroy', () => {
    //     clearTimeout(animationTimeoutId)
    //   })
    // },
  }
}
</script>

<style lang="scss" scoped>
// .news-ticker {
//   width: 100%;
//   height: 100px;
//   overflow: hidden;
//   background-color: $secondary;

//   .ticker-list {
//     height: 100%;
//     display: flex;
//     align-items: center;
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     animation: ticker linear infinite;

//     li {
//       margin-right: 20px;
//       white-space: nowrap;
//       color: white;

//       .item-wrapper {
//         border-right: 3px solid rgba($gray, 0.1);
//         padding-right: 1rem;
//         .price {
//           text-align: center;
//           color: $primary;
//         }

//         .nav-link {
//           &:hover {
//             color: $primary !important;
//           }
//         }
//       }
//     }
//   }
// }
// @keyframes ticker {
//   0% {
//     transform: translateX(0);
//   }

//   100% {
//     transform: translateX(-100%);
//   }
// }
.breaking-news{
  background-color: $gray;
  padding: 1rem;
  .fish-list{
    .fish-wrapper{
      // margin-right: 20px;
      white-space: nowrap;
      color: white;
      border-right: 3px solid rgba($gray, 0.1);
      // padding-right: 1rem;
      padding:0.5rem 1rem;
      margin:0rem 0.5rem;
      font-weight: bold;
      // border-right: 1px solid $primary;
      .price {
        color: $primary;
        .currency{
          opacity: 0.75;
        }
      }
      .nav-link {
        color: $secondary;
        &:hover {
          color: $primary !important;
        }
        .name{
          text-align: center;
        }
      }
    }
  }
}
</style>