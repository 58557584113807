<template>
  <footer id="footer" class="p-4 mt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-4 my-2">
          <div class="company-logo">
            <router-link to="/">
                <img :src="mediaURL+info.logo2" alt="company logo">
            </router-link>
          </div>
          <div class="company-slogan">
            <p class="my-2">{{ $t('vision') }}</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 my-2">
          <div class="row">
            <div class="col-12">
              <h4 class="fw-bold">{{ $t('quickLinks') }}</h4>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6" v-for="item in navList" :key="item.id">
              <div class="my-2">
                <router-link class="nav-link"  :to="{'name':item.link}">{{ item[locale] }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 my-2">
          <div class="row">
            <div class="col-12">
              <h4 class="fw-bold">{{ $t('contactUs') }}</h4>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-9">
              <table class="table table-borderless iconlinks">
                <tbody>
                  <tr>
                    <td><i class="pi pi-map-marker"></i></td>
                    <td>{{ info.address }}</td>
                  </tr>
                  <tr>
                    <td><i class="pi pi-envelope"></i></td>
                    <td><a :href="`mailto:${info.email}`" target="_blank">{{ info.email }}</a></td>
                  </tr>
                  <tr>
                    <td><i class="pi pi-phone"></i></td>
                    <td><a :href="`tell:${info.mobile}`" target="_blank">{{ info.mobile }}</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-3">
              <div class="social-media-links ">
                <div v-for="platform in socialMedia" :key="platform.name" >
                  <span v-if="platform.url" class="social-media-link" @click="navigateTo(platform.url)">
                    <i :class="'pi ' + platform.icon" ></i>
                  </span>
                  <!-- <span>{{ platform.name }}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr>
          <div class="copy-rights text-center py-4">
            <h6 class="text-muted" v-if="info">
              <span>{{info.copyright.split('|')[0] }}</span>
              <span>&copy;</span>
              <router-link class="text-muted" to="/">
                {{ info.name }}
              </router-link>
            </h6>
          </div>
        </div>
      </div>
    </div>
    <BackToTop></BackToTop>
  </footer>
</template>
<i18n src="@/lang/layout/footer.json"></i18n>

<script>
import BackToTop from "../utilities/BackToTop.vue";
export default {
  name: "Footer",
  components: {
    BackToTop
  },
  data() {
    return {
      navList: [
        { en: "Home", ar: "الرئيسية", link: "home", id: 1 },
        { en: "Local Market", ar: "سوق محلي", link: "localMarket", id: 2 },
        {
          en: "International Market",
          ar: "سوق دولي",
          link: "internationalMarket",
          id: 3
        },
        {
          en: "Special Products",
          ar: "المنتجات المميزة",
          link: "specialProducts",
          id: 4
        },
        { en: "Auctions", ar: "المزادات", link: "auctions", id: 5 },
        // {en:"Requests",ar:"الطلبات",link:"requests",id:6},
        { en: "News", ar: "الأخبار", link: "news", id: 7 },
        { en: "About Us", ar: "من نحن", link: "aboutUs", id: 8 },
        { en: "Contact Us", ar: "اتصل بنا", link: "contactUs", id: 9 }
      ]
      // socialMedia: [
      //   { name: 'Facebook', icon: 'pi-facebook', url: 'https://www.facebook.com/' },
      //   { name: 'Twitter', icon: 'pi-twitter', url: 'https://twitter.com/' },
      //   { name: 'Instagram', icon: 'pi-instagram', url: 'https://www.instagram.com/' },
      //   { name: 'LinkedIn', icon: 'pi-linkedin', url: 'https://www.linkedin.com/' },
      //   { name: 'Youtube', icon: 'pi-youtube', url: 'https://www.youtube.com/' },
      //   // add more social media links here
      // ]
    };
  },
  computed: {
    mediaURL() {
      return this.$store.getters["urls/mediaURL"];
    },
    mainStaticData() {
      return this.$store.getters["mainStaticData/mainStaticData"];
    },
    info() {
      if (this.mainStaticData) return this.mainStaticData.data.info;
      else return false;
    },
    socialMedia() {
      let platforms = [];
      if (this.info) {
        platforms = [
          { name: "Facebook", icon: "pi-facebook", url: this.info.facebook },
          { name: "Twitter", icon: "pi-twitter", url: this.info.twitter },
          { name: "Instagram", icon: "pi-instagram", url: this.info.instagram },
          { name: "Youtube", icon: "pi-youtube", url: this.info.youtube }
          // add more social media links here
        ];
        return platforms;
      } else return platforms;
    }
  },
  mounted() {},

  methods: {
    navigateTo: function(url) {
      window.open(url, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
#footer {
  background-color: $gray;
  .company-logo {
    img {
      max-width: 100%;
    }
  }
  .company-slogan {
    p {
      line-height: 2;
      text-align: justify;
      padding: 1rem;
    }
  }
  .table {
    td {
      background-color: transparent !important;
      color: $secondary;
    }
  }
  .iconlinks {
    td {
      .pi {
        color: $primary;
      }
      a {
        color: $secondary;
      }
    }
  }
  hr {
    border-top: 3px solid;
  }
  .copy-rights {
    a {
      color: $secondary;
      // opacity: 0.75;
    }
  }
  .social-media-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .social-media-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      // margin-right: 10px;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      i {
        font-size: 28px;
        color: $secondary;
        &:hover {
          color: $primary;
        }
      }
      span {
        font-size: 14px;
        color: $secondary;
        margin-top: 5px;
      }
    }
  }
}
::v-deep(.social-btn) {
  background: $secondary;
  &:hover {
    border: none !important;
    outline: none !important;
  }
}
</style>