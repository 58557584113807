<template>
  <div class="news-section" v-if="newsList.length">
    <div class="row my-5">
      <div class="col-12">
        <div class="heading d-flex justify-content-between">
          <h6>
            <span class="custom-title-circle">{{ $t('headingHighlight') }}</span>
          </h6>
          <router-link :to="{'name':'news'}" class="nav-link text-muted">
            <span>{{ $t('buttonLabels.WatchMore') }}</span>
            <img src="@/assets/home/fish5.svg" alt="" class="mx-1">
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 my-4" v-for="news in newsList" :key="news.id">
        <div class="news-wrapper my-2">
          <div class="row">
            <div class="col-sm-6 my-2">
              <div class="news-img">
                <img :src="mediaURL+'Posts/'+news.image" alt="">
                <!-- <img :src="mediaURL+'Posts/'+news.image" alt=""> -->
              </div>
            </div>
            <div class="col-sm-6 my-2" >
              <div class="news-content h-100 d-flex flex-column justify-content-center align-items-start">
                <h4 class="heading-underline" :class="[locale=='ar'?'rtl-underline':'ltr-underline']">
                  {{ news.title}}
                </h4>
                <div class="news-body" v-html="truncateBody(news.body)"></div>
                <router-link :to="{ 'name': 'newsDetails', params: { newsId: news.id } }" >{{ $t('buttonLabels.readMore') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<i18n src="@/lang/global/global.json"></i18n>
<i18n src="@/lang/home/news.json"></i18n>
<script>
export default {
  name: 'News',

  data() {
    return {
      
    };
  },
  computed:{
    mediaURL () {
      return this.$store.getters['urls/mediaURL']
    },
    mainStaticData () {
      return this.$store.getters['mainStaticData/mainStaticData']
    },
    newsList () {
      if(this.mainStaticData)
        return this.$store.getters['mainStaticData/mainStaticData']["data"]["blogs"]
      else return []
    },
  },
  mounted() {
    
  },

  methods: {
    truncateBody(body){
        if(body){
          if (body.length > 50) {
            return body.substring(0, 50) + '...'
          } else {
            return body
          }
        }
      },
  },
};
</script>

<style lang="scss" scoped>
.news-section{
  .custom-title-circle{
    padding: 0.25rem 0.75rem;
    border: 3px solid $primary;
    border-radius: 50%;
  }
  .news-wrapper{
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: white;
    .news-img{
      aspect-ratio: 1/1;
      img{
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        object-fit: cover;
      }
    }
    
    .news-content{
      .heading-underline {
        position: relative;
        display: inline-block;
        margin-bottom:1rem; /* Add margin to create space between the heading and underline */
        color: $secondary;
      }
      .heading-underline::after {
        content: "";
        position: absolute;
        bottom: -5px; /* Position the underline just below the text */
        width: 2rem; /* Set the width of the underline */
        height: 3px; /* Set the height of the underline */
        background-color: $secondary; /* Set the color of the underline */
      }
      .heading-underline.ltr-underline::after{
        right: auto;
        left: 0;
      }
      .heading-underline.rtl-underline::after{
        right: 0;
        left: auto;
      }
      a{
        color: $primary;
        text-decoration: none;

      }
    }
  }
}
</style>