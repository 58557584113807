// import { beharAxios } from '@/packages/axios/beharAxios'
// export default {
//   namespaced: true,
//   state: {
//     categories: [],
//     categoriesLoading: false,
//     categoriesError: false,
//   },
//   // get the currant state value
//   getters: {
//     categories: state => state.categories,
//     categoriesLoading: state => state.categoriesLoading,
//     categoriesError: state => state.categoriesError,
//   },
//   // use to perform un mutate or change states
//   mutations: {
//     setCategories(state, data) {
//       state.categories = data
//     },
//     setCategoriesLoading(state, loading) {
//       state.categoriesLoading = loading
//     },
//     setCategoriesError(state, error) {
//       state.categoriesError = error
//     },
//   },
//   // use to perform un asynchronous tasks
//   actions: {
//     async loadCategories({ commit }, payload) {
//       commit('setCategoriesLoading', true)
//       try {
//         const response = await beharAxios.post('SnD/Categories', {
//           "client_id": 1
//         })
//         console.log(response)
//         commit('setCategories', [...response.data.data])
//         return Promise.resolve(response)
//       } catch (error) {
//         commit('setCategoriesError', error)
//         return Promise.reject(error)
//       }
//       finally {
//         commit('setCategoriesLoading', false)
//       }
//     },
//   },
// };






import axios from 'axios'
export default {
  namespaced: true,
  state: {
    categories: [],
    categoriesLoading: false,
    categoriesError: false,
  },
  // get the currant state value
  getters: {
    categories: state => state.categories,
    categoriesLoading: state => state.categoriesLoading,
    categoriesError: state => state.categoriesError,
  },
  // use to perform un mutate or change states
  mutations: {
    setCategories(state, data) {
      state.categories = data
    },
    setCategoriesLoading(state, loading) {
      state.categoriesLoading = loading
    },
    setCategoriesError(state, error) {
      state.categoriesError = error
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    async loadCategories({ commit }, payload) {
      commit('setCategoriesLoading', true)
      try {
        const response = await axios.get('platforms/behar-market/masters/CategoriesList')
        console.log(response)
        commit('setCategories', [...response.data.data])
        return Promise.resolve(response)
      } catch (error) {
        commit('setCategoriesError', error)
        return Promise.reject(error)
      }
      finally {
        commit('setCategoriesLoading', false)
      }
    },
  },
};
