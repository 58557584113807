import axios from 'axios'
export default {
  namespaced: true,
  state: {
    permissions: {
      local_buyer: {
        canView: ['wallet', 'account', 'purchaseOrders', 'myFavorites'],
        canBid: false,
      },
      fisherman: {
        canView: ['wallet', 'account', 'sellOrders', 'purchaseOrders', 'myProducts', 'addProduct', 'myFavorites'],
        canBid: false,
      },
      international_seller: {
        canView: [
          'wallet', 'account', 'sellOrders', 'purchaseOrders', 'myProducts', 'addProduct', 'myAuctions', 'addAuction', 'auctions', 'myFavorites', 'beharMarket', 'beharMarketMain', 'myDemands'
        ],
        canBid: true,
      },
      local_seller: {
        canView: [
          'wallet', 'account', 'sellOrders', 'purchaseOrders', 'myProducts', 'addProduct', 'myAuctions', 'addAuction', 'auctions', 'myFavorites'
        ],
        canBid: true,
      }
    }
  },
  // get the currant state value
  getters: {
    getPermissions: state => state.permissions,
  },
  // use to perform un mutate or change states
  mutations: {},
  // use to perform un asynchronous tasks
  actions: {},
};
