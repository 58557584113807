import axios from 'axios'
export default {
  namespaced: true,
  state: {
    form: {
      title:"",
      body:"",
      fish_id: null,
      city_id: null,
      size: "",
      quality: "",
      quantity: null,
      weight: null,
      price: null,
      image:null,
    },
    // form: {
    //   title:"تجربة تقديم عرض",
    //   body:"تجربة تقديم عرض",
    //   fish_id: 1,
    //   city_id: 5,
    //   size: "small",
    //   quality: "A",
    //   quantity: 50,
    //   weight: 100,
    //   price: 23,
    //   image:null,
    // },
    serverResponse: null,
    serverError: false,
    serverLoading: false
  },
  // get the currant state value
  getters: { 
    getForm: state => state.form,
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_FORM_DATA(state, formData) {
      state.form = formData;
    },
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    }
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    setForm({ commit }){
      commit('SET_FORM_DATA',form);
    },
    resetForm({ commit }){
      const form = {
        title:"",
        body:"",
        fish_id: null,
        city_id: null,
        size: "",
        quality: "",
        quantity: null,
        weight: null,
        price: null,
        image:null,
      }
      // const form = {
      //   title:"تجربة تقديم عرض",
      //   body:"تجربة تقديم عرض",
      //   fish_id: 1,
      //   city_id: 5,
      //   size: "small",
      //   quality: "A",
      //   quantity: 50,
      //   weight: 100,
      //   price: 23,
      //   image:null,
      // }
      commit('SET_FORM_DATA',form);
    },
    async create({ commit }, formData) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.post('requests/create', formData);
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', error.response.data.errors);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
  },
};
