import { beharAxios } from '@/packages/axios/beharAxios'
import urls from './urls'
import login from './login'
import subscription from './subscription'
import categories from './categories'
import sizes from './sizes'
import fishTypes from './fishTypes'
import supply from './supply'
import demand from './demand'
import demandStatus from './demandStatus'
import supplyStatus from './supplyStatus'
import myDemands from './myDemands'

export default {
  namespaced: true,
  state: {},
  // get the currant state value
  getters: {},
  // use to perform un mutate or change states
  mutations: {},
  // use to perform un asynchronous tasks
  actions: {},
  modules: {
    urls,
    login,
    subscription,
    categories,
    sizes,
    fishTypes,
    supply,
    demand,
    demandStatus,
    supplyStatus,
    myDemands,
  }
};
