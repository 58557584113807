export default {
  data() {
    return {
      pagination: {
        offset: 0,
        rows: 6,
        pageNumber: 0,
        rowsOptions:[6, 12, 24]
      },
    };
  },
}
