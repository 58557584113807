import axios from 'axios'
export default {
  namespaced: true,
  state: {
    form: {
      type:"",
      f_name:"",
      l_name:"",
      mobile:"",
      email:"",
      address:"",
      n_id:"",
      password:"",
      password_confirmation:"",
      cr_number:"",
    },
    // form: {
    //   type:"local_buyer",
    //   f_name:"local_buyer",
    //   l_name:"local_buyer",
    //   mobile:"9661234567",
    //   email:"local_buyer@local.com",
    //   address:"local_buyer",
    //   n_id:"local_buyer",
    //   password:"123456789",
    //   password_confirmation:"123456789",
    //   cr_number:"12345678910",
    // },
    serverResponse: null,
    serverError: false,
    serverLoading: false
  },
  // get the currant state value
  getters: { 
    getForm: state => state.form,
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_FORM_DATA(state, formData) {
      state.form = formData;
    },
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    }
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    resetForm({ commit }){
      const form = {
        type:"",
        f_name:"",
        l_name:"",
        mobile:"",
        email:"",
        address:"",
        n_id:"",
        password:"",
        password_confirmation:"",
        cr_number:"",
      }
      commit('SET_FORM_DATA',form);
    },
    async signUp({ commit }, formData) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.post('register', formData);
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_RESPONSE', null);
        commit('SET_SERVER_ERROR', error.response.data.errors);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    }
  },
};
