// import { beharAxios } from '@/packages/axios/beharAxios'
// export default {
//   namespaced: true,
//   state: {
//     supplyStatus: {},
//     supplyStatusLoading: false,
//     supplyStatusError: false,
//   },
//   // get the currant state value
//   getters: {
//     supplyStatus: state => state.supplyStatus,
//     supplyStatusLoading: state => state.supplyStatusLoading,
//     supplyStatusError: state => state.supplyStatusError,
//   },
//   // use to perform un mutate or change states
//   mutations: {
//     setSupplyStatus(state, data) {
//       state.supplyStatus = data
//     },
//     setSupplyStatusLoading(state, loading) {
//       state.supplyStatusLoading = loading
//     },
//     setSupplyStatusError(state, error) {
//       state.supplyStatusError = error
//     },
//   },
//   // use to perform un asynchronous tasks
//   actions: {
//     async loadSupplyStatus({ commit }, payload) {
//       commit('setSupplyStatusLoading', true)
//       try {
//         const response = await beharAxios.post('SnD/Status_List', {
//           "client_id": 1,
//           "status_type": "Supplys"
//         })
//         console.log(response)
//         commit('setSupplyStatus', response.data.data)
//         return Promise.resolve(response)
//       } catch (error) {
//         commit('setSupplyStatusError', error.response.data)
//         return Promise.reject(error)
//       }
//       finally {
//         commit('setSupplyStatusLoading', false)
//       }
//     },
//   },
// };











import axios from 'axios'
export default {
  namespaced: true,
  state: {
    supplyStatus: {},
    supplyStatusLoading: false,
    supplyStatusError: false,
  },
  // get the currant state value
  getters: {
    supplyStatus: state => state.supplyStatus,
    supplyStatusLoading: state => state.supplyStatusLoading,
    supplyStatusError: state => state.supplyStatusError,
  },
  // use to perform un mutate or change states
  mutations: {
    setSupplyStatus(state, data) {
      state.supplyStatus = data
    },
    setSupplyStatusLoading(state, loading) {
      state.supplyStatusLoading = loading
    },
    setSupplyStatusError(state, error) {
      state.supplyStatusError = error
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    async loadSupplyStatus({ commit }, payload) {
      commit('setSupplyStatusLoading', true)
      try {
        const response = await axios.get('platforms/behar-market/list/Status_List?status_type=Supplys')
        console.log(response)
        commit('setSupplyStatus', response.data.data)
        return Promise.resolve(response)
      } catch (error) {
        commit('setSupplyStatusError', error.response.data)
        return Promise.reject(error)
      }
      finally {
        commit('setSupplyStatusLoading', false)
      }
    },
  },
};
