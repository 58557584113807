import axios from 'axios'
export default {
  namespaced: true,
  state: {
    myRequests: null,
    myRequestsLoading: false,
    myRequestsError: false,
    ////////////////////////////////
    requestDetails: null,
    requestDetailsLoading: false,
    requestDetailsError: false,
    ////////////////////////////////
    requestUpdate: null,
    requestUpdateLoading: false,
    requestUpdateError: false,
    ////////////////////////////////
    offer: null,
    offerLoading: false,
    offerError: false,
  },
  // get the currant state value
  getters: { 
    myRequestsData: state => state.myRequests,
    myRequestsLoading: state => state.myRequestsLoading,
    myRequestsError: state => state.myRequestsError,
    /////////////////////////////////////////////
    requestDetailsData: state => state.requestDetails,
    requestDetailsLoading: state => state.requestDetailsLoading,
    requestDetailsError: state => state.requestDetailsError,
    /////////////////////////////////////////////
    requestUpdateData: state => state.requestUpdate,
    requestUpdateLoading: state => state.requestUpdateLoading,
    requestUpdateError: state => state.requestUpdateError,
    /////////////////////////////////////////////
    offerData: state => state.offer,
    offerLoading: state => state.offerLoading,
    offerError: state => state.offerError,

  },
  // use to perform un mutate or change states
  mutations: {
    setMyRequests (state, data) {
      state.myRequests = data
    },
    setMyRequestsLoading (state, loading) {
      state.myRequestsLoading = loading
    },
    setMyRequestsError (state, error) {
      state.myRequestsError = error
    },
    //////////////////////////////////////////
    setRequestDetails (state, data) {
      state.requestDetails = data
    },
    setRequestDetailsLoading (state, loading) {
      state.requestDetailsLoading = loading
    },
    setRequestDetailsError (state, error) {
      state.requestDetailsError = error
    },
    //////////////////////////////////////////
    setRequestUpdate (state, data) {
      state.requestUpdate = data
    },
    setRequestUpdateLoading (state, loading) {
      state.requestUpdateLoading = loading
    },
    setRequestUpdateError (state, error) {
      state.requestUpdateError = error
    },
    //////////////////////////////////////////
    setOffer (state, data) {
      state.offer = data
    },
    setOfferLoading (state, loading) {
      state.offerLoading = loading
    },
    setOfferError (state, error) {
      state.offerError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadMyRequests ({ commit },payload) {
      commit('setMyRequestsLoading', true)
      try {
        const response = await axios.get('requests/my',{params: payload})
        // console.log(response)
        commit('setMyRequests', response.data)
        commit('setMyRequestsLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setMyRequestsLoading', false)
        commit('setMyRequestsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async loadRequestDetails({ commit }, payload) {
      try {
        commit('setRequestDetailsLoading', true)
        const response = await axios.get(`requests/show/${payload.id}`);
        commit('setRequestDetails', response.data)
        commit('setRequestDetailsLoading', false)
        commit('setRequestDetailsError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setRequestDetailsLoading', false)
        commit('setRequestDetailsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async updateRequest({ commit },payload) {
      try {
        commit('setRequestUpdateLoading', true)
        const response = await axios.post(`requests/update/${payload.id}`,payload.formData);
        commit('setRequestUpdate', response.data)
        commit('setRequestUpdateLoading', false)
        commit('setRequestUpdateError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setRequestUpdateLoading', false)
        commit('setRequestUpdateError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async acceptOffer ({ commit },payload) {
      commit('setOfferLoading', true)
      try {
        const response = await axios.get(`requests/offers/${payload.id}/accept`)
        // console.log(response)
        commit('setOffer', response.data)
        commit('setOfferLoading', false)
        commit('setOfferError', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setOfferLoading', false)
        commit('setOfferError',error.response.data)
        return Promise.reject(error.response.data)
      }
    }, 
  },
};
