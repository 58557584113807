<template>
  <div id="app" class="container-fluid p-0 m-0">
    <!-- Remember to handle error page view -->
    <Loader v-if="mainStaticDataLoading||mainStaticDataError"></Loader>
    <div class="" v-else>
      <Header></Header>
      <router-view />
      <Footer></Footer>
    </div>
    <Toast :position="locale=='en'?'top-right':'top-left'" />
  </div>
</template>
<script>
import Header from '@/components/global/layout/Header.vue';
import Footer from '@/components/global/layout/Footer.vue';
import Loader from '@/components/global/utilities/Loader.vue';
export default {
  components: {
    Header,
    Footer,
    Loader
  },
  computed: {
    mainStaticData() {
      return this.$store.getters['mainStaticData/mainStaticData'];
    },
    info() {
      if (this.mainStaticData) return this.mainStaticData.data.info;
      else return false;
    },
    mainStaticDataLoading() {
      return this.$store.getters['mainStaticData/mainStaticDataLoading'];
    },
    mainStaticDataError() {
      return this.$store.getters['mainStaticData/mainStaticDataError'];
    }
  },
  mounted() {
    this.loadData();

    if (localStorage.getItem('token')) this.loadProfile();
    ////////////////////
  },
  methods: {
    async loadProfile() {
      await this.$store
        .dispatch('userStatus/getUserProfile')
        .then(res => {
          // console.log(res);
          // this.$store.dispatch('userStatus/setUserStatus',res)
        })
        .catch(err => {
          // console.log(err);
          // this.$store.dispatch('userStatus/setUserStatus',err)
        });
    },
    async loadData() {
      try {
        await this.$store
          .dispatch('mainStaticData/loadMainStaticData')
          .then(res => {
            document.title = this.info.name;
          });
        // Do something with the loaded data
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="scss">
#app {
  color: $secondary;
  width: 100%;
  height: 100%;
}
a {
  text-decoration: auto !important;
}
.router-link-exact-active.router-link-active {
  color: $primary;
  font-weight: bold;
}
</style>
