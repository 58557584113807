import { render, staticRenderFns } from "./SalesTrafficBrief.vue?vue&type=template&id=0ae65fd8&scoped=true"
import script from "./SalesTrafficBrief.vue?vue&type=script&lang=js"
export * from "./SalesTrafficBrief.vue?vue&type=script&lang=js"
import style0 from "./SalesTrafficBrief.vue?vue&type=style&index=0&id=0ae65fd8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae65fd8",
  null
  
)

/* custom blocks */
import block0 from "@/lang/global/global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5ChomeView%5CSalesTrafficBrief.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/lang/home/brief.json?vue&type=custom&index=1&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5ChomeView%5CSalesTrafficBrief.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports