// import { beharAxios } from '@/packages/axios/beharAxios'
// export default {
//   namespaced: true,
//   state: {
//     fishTypes: [],
//     fishTypesLoading: false,
//     fishTypesError: false,
//   },
//   // get the currant state value
//   getters: {
//     fishTypes: state => state.fishTypes,
//     fishTypesLoading: state => state.fishTypesLoading,
//     fishTypesError: state => state.fishTypesError,
//   },
//   // use to perform un mutate or change states
//   mutations: {
//     setFishTypes(state, data) {
//       state.fishTypes = data
//     },
//     setFishTypesLoading(state, loading) {
//       state.fishTypesLoading = loading
//     },
//     setFishTypesError(state, error) {
//       state.fishTypesError = error
//     },
//   },
//   // use to perform un asynchronous tasks
//   actions: {
//     async loadFishTypes({ commit }, payload) {
//       commit('setFishTypesLoading', true)
//       try {
//         const response = await beharAxios.post('SnD/FishTypes', {
//           "client_id": 1
//         })
//         console.log(response)
//         commit('setFishTypes', response.data.data.fish_types)
//         return Promise.resolve(response)
//       } catch (error) {
//         commit('setFishTypesError', error.response.data)
//         return Promise.reject(error)
//       }
//       finally {
//         commit('setFishTypesLoading', false)
//       }
//     },
//   },
// };













import axios from 'axios'
export default {
  namespaced: true,
  state: {
    fishTypes: [],
    fishTypesLoading: false,
    fishTypesError: false,
  },
  // get the currant state value
  getters: {
    fishTypes: state => state.fishTypes,
    fishTypesLoading: state => state.fishTypesLoading,
    fishTypesError: state => state.fishTypesError,
  },
  // use to perform un mutate or change states
  mutations: {
    setFishTypes(state, data) {
      state.fishTypes = data
    },
    setFishTypesLoading(state, loading) {
      state.fishTypesLoading = loading
    },
    setFishTypesError(state, error) {
      state.fishTypesError = error
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    async loadFishTypes({ commit }, payload) {
      commit('setFishTypesLoading', true)
      try {
        const response = await axios.get('platforms/behar-market/list/FishTypes')
        console.log(response)
        commit('setFishTypes', response.data.data.fish_types)
        return Promise.resolve(response)
      } catch (error) {
        commit('setFishTypesError', error.response.data)
        return Promise.reject(error)
      }
      finally {
        commit('setFishTypesLoading', false)
      }
    },
  },
};
