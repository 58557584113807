<template>
    <Button class="back-to-top p-2" icon="pi pi-angle-up" id="back-to-top-btn" v-if="showButton" @click="scrollToTop" />
</template>

<script>
export default {
  name: 'BackToTop',

  data() {
    return {
      showButton: false
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    handleScroll() {
      this.showButton = window.pageYOffset > 100;
    }
  },
};
</script>

<style lang="scss" scoped>
  #back-to-top-btn {
    position: fixed;
    z-index: 15;
    bottom: 40px;
    right: 40px;
    border: none;
    outline: none;
    width: 3rem;
    height: 3rem;
    background-color:$secondary;
    &:hover{
      background-color: $primary;
    }
  }
  @media (max-width: 768px) {
    #back-to-top-btn {
      bottom: 10px;
      right: 10px;
      width: 2rem;
      height: 2rem;
  }
}

::v-deep(.back-to-top){
      .p-button-label{
        display: none;
      }
}
</style>