<template>
  <div class="fish-list">
    <NewsTicker  :fishes="fishList" />
  </div>
</template>

<script>
import NewsTicker  from '@/components/homeView/fish/NewsTicker.vue'
export default {
  name: 'FishList',
  components:{
    NewsTicker ,
  },
  data() {
    return {
    };
  },
  computed:{
    mainStaticData () {
        return this.$store.getters['mainStaticData/mainStaticData']
    },
    fishList () {
      if(this.mainStaticData)
        return this.$store.getters['mainStaticData/mainStaticData']["data"]["fish"]
      else return []
    },
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
  .fish-list{
    position: relative;
    z-index: 5;
  }
</style>