import axios from 'axios'
export default {
  namespaced: true,
  state: {
    news: null,
    newsLoading: false,
    newsError: false,
    ////////////////////////////////
    newsDetails: null,
    newsDetailsLoading: false,
    newsDetailsError: false,
  },
  // get the currant state value
  getters: { 
    newsData: state => state.news,
    newsLoading: state => state.newsLoading,
    newsError: state => state.newsError,
    /////////////////////////////////////////////
    newsDetailsData: state => state.newsDetails,
    newsDetailsLoading: state => state.newsDetailsLoading,
    newsDetailsError: state => state.newsDetailsError,

  },
  // use to perform un mutate or change states
  mutations: {
    setNews (state, data) {
      state.news = data
    },
    setNewsLoading (state, loading) {
      state.newsLoading = loading
    },
    setNewsError (state, error) {
      state.newsError = error
    },
    //////////////////////////////////////////
    setNewsDetails (state, data) {
      state.newsDetails = data
    },
    setNewsDetailsLoading (state, loading) {
      state.newsDetailsLoading = loading
    },
    setNewsDetailsError (state, error) {
      state.newsDetailsError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadNews ({ commit },payload) {
      commit('setNewsLoading', true)
      try {
        const response = await axios.get('blogs',{params: payload})
        // console.log(response)
        commit('setNews', response.data)
        commit('setNewsLoading', false)
      } catch (error) {
        commit('setNewsLoading', false)
        commit('setNewsError',error.response.data)
      }
    },
    ////////////////////////////////////////
    async loadNewsDetails({ commit }, payload) {
      try {
        commit('setNewsDetailsLoading', true)
        const response = await axios.get(`blogs/${payload.id}`);
        commit('setNewsDetails', response.data)
        commit('setNewsDetailsLoading', false)
        commit('setNewsDetailsError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setNewsDetailsLoading', false)
        commit('setNewsDetailsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
  },
};
