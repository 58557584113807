import axios from 'axios'
export default {
  namespaced: true,
  state: {
    cart: JSON.parse(localStorage.getItem('cart')) || [],
  },
  // get the currant state value
  getters: {
    cart: state => state.cart,
  },
  // use to perform un mutate or change states
  mutations: {
    setCart(state, payload) {
      state.cart = payload;
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    setCart({ commit }, payload) {
      commit('setCart', payload)
    },
  },
};
