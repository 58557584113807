import { render, staticRenderFns } from "./CartItem.vue?vue&type=template&id=b9c9b608&scoped=true"
import script from "./CartItem.vue?vue&type=script&lang=js"
export * from "./CartItem.vue?vue&type=script&lang=js"
import style0 from "./CartItem.vue?vue&type=style&index=0&id=b9c9b608&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9c9b608",
  null
  
)

/* custom blocks */
import block0 from "@/lang/global/global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5Ccart%5CCartItem.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports