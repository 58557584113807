import axios from 'axios'
export default {
  namespaced: true,
  state: {
    requests: null,
    requestsLoading: false,
    requestsError: false,
    ////////////////////////////////
    requestDetails: null,
    requestDetailsLoading: false,
    requestDetailsError: false,
  },
  // get the currant state value
  getters: { 
    requestsData: state => state.requests,
    requestsLoading: state => state.requestsLoading,
    requestsError: state => state.requestsError,
    /////////////////////////////////////////////
    requestDetailsData: state => state.requestDetails,
    requestDetailsLoading: state => state.requestDetailsLoading,
    requestDetailsError: state => state.requestDetailsError,
  },
  // use to perform un mutate or change states
  mutations: {
    setRequests (state, data) {
      state.requests = data
    },
    setRequestsLoading (state, loading) {
      state.requestsLoading = loading
    },
    setRequestsError (state, error) {
      state.requestsError = error
    },
    //////////////////////////////////////////
    setRequestDetails (state, data) {
      state.requestDetails = data
    },
    setRequestDetailsLoading (state, loading) {
      state.requestDetailsLoading = loading
    },
    setRequestDetailsError (state, error) {
      state.requestDetailsError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadRequests ({ commit },payload) {
      commit('setRequestsLoading', true)
      try {
        const response = await axios.get('requests/list',{params: payload})
        // console.log(response)
        commit('setRequests', response.data)
        commit('setRequestsError', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setRequestsError',error.response.data)
        return Promise.reject(error.response.data)
      }
      finally {
        commit('setRequestsLoading', false)
      }
    },
    ////////////////////////////////////////
    async loadRequestDetails({ commit }, payload) {
      try {
        commit('setRequestDetailsLoading', true)
        const response = await axios.get(`requests/show/${payload.id}`);
        commit('setRequestDetails', response.data)
        commit('setRequestDetailsError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setRequestDetailsError',error.response.data)
        return Promise.reject(error.response.data)
      }
      finally {
        commit('setRequestDetailsLoading', false)
      }
    },
  },
};
