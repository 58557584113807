import axios from 'axios';

// Create a new behar instance of Axios and its default options
const beharAxios = axios.create({
  baseURL: "https://uat-clientapi.behar.market/api/",
  headers: {
    'content-type': 'application/json',
    // 'x-localization': localStorage.getItem("locale")
  },
});

if (!localStorage.getItem("locale")) {
  localStorage.setItem("locale", 'ar')
}

// global request interceptors configuration
beharAxios.interceptors.request.use(config => {
  // config.headers['x-localization'] = localStorage.getItem("locale");
  if (localStorage.getItem('beharToken')) {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('beharToken');
  }
  return config;
},
  error => {
    return Promise.reject(error);
  });



// global response interceptors configuration
let refreshing = false;
let count = 0
beharAxios.interceptors.response.use(
  response => response,
  async error => {
    // console.log(error);
    if ((error.response.status === 401) && !refreshing && localStorage.getItem('beharToken') && count < 10) {
      console.log("Refreshing token")
      refreshing = true;
      try {
        // const companyUser = {
        //   "user_name": "behar-al-fahadi",
        //   "password": "123456"
        // }
        const { data } = await beharAxios.post('user/login', companyUser)
        // const { data } = await axios.get('platforms/behar-market')
        console.log(data)
        let token = data.data.token
        console.log(token)
        console.log('success in refreshing token');
        localStorage.removeItem('beharToken')
        localStorage.setItem("beharToken", token)
        return beharAxios({
          ...error.config,
          headers: { ...error.config.headers, Authorization: `Bearer ${token}` },
          sent: true
        });
      } catch (err) {
        console.log(err);
        console.log('error in refreshing token');
        localStorage.removeItem("beharToken");
        window.location.href = "/auth/sign-in";
      }
      finally {
        refreshing = false;
        count += 1
      }
    }
    return Promise.reject(error);
  });




export { beharAxios };

















