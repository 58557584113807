import axios from 'axios'
export default {
  namespaced: true,
  state: {
    myAuctions: null,
    myAuctionsLoading: false,
    myAuctionsError: false,
    ////////////////////////////////
    auctionDetails: null,
    auctionDetailsLoading: false,
    auctionDetailsError: false,
    ////////////////////////////////
    auctionUpdate: null,
    auctionUpdateLoading: false,
    auctionUpdateError: false,
    ////////////////////////////////
    bid: null,
    bidLoading: false,
    bidError: false,

  },
  // get the currant state value
  getters: { 
    myAuctionsData: state => state.myAuctions,
    myAuctionsLoading: state => state.myAuctionsLoading,
    myAuctionsError: state => state.myAuctionsError,
    /////////////////////////////////////////////
    auctionDetailsData: state => state.auctionDetails,
    auctionDetailsLoading: state => state.auctionDetailsLoading,
    auctionDetailsError: state => state.auctionDetailsError,
    /////////////////////////////////////////////
    auctionUpdateData: state => state.auctionUpdate,
    auctionUpdateLoading: state => state.auctionUpdateLoading,
    auctionUpdateError: state => state.auctionUpdateError,
    /////////////////////////////////////////////
    bidData: state => state.bid,
    bidLoading: state => state.bidLoading,
    bidError: state => state.bidError,


  },
  // use to perform un mutate or change states
  mutations: {
    setMyAuctions (state, data) {
      state.myAuctions = data
    },
    setMyAuctionsLoading (state, loading) {
      state.myAuctionsLoading = loading
    },
    setMyAuctionsError (state, error) {
      state.myAuctionsError = error
    },
    //////////////////////////////////////////
    setAuctionDetails (state, data) {
      state.auctionDetails = data
    },
    setAuctionDetailsLoading (state, loading) {
      state.auctionDetailsLoading = loading
    },
    setAuctionDetailsError (state, error) {
      state.auctionDetailsError = error
    },
    //////////////////////////////////////////
    setAuctionUpdate (state, data) {
      state.auctionUpdate = data
    },
    setAuctionUpdateLoading (state, loading) {
      state.auctionUpdateLoading = loading
    },
    setAuctionUpdateError (state, error) {
      state.auctionUpdateError = error
    },
    //////////////////////////////////////////
    setBid (state, data) {
      state.bid = data
    },
    setBidLoading (state, loading) {
      state.bidLoading = loading
    },
    setBidError (state, error) {
      state.bidError = error
    },
       
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadMyAuctions ({ commit },payload) {
      commit('setMyAuctionsLoading', true)
      try {
        const response = await axios.get('product/my/local/auction',{
          params: payload
        })
        // console.log(response)
        commit('setMyAuctions', response.data)
        commit('setMyAuctionsLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setMyAuctionsLoading', false)
        commit('setMyAuctionsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async loadAuctionDetails({ commit }, payload) {
      try {
        commit('setAuctionDetailsLoading', true)
        const response = await axios.get(`auction/show/${payload.id}`);
        commit('setAuctionDetails', response.data)
        commit('setAuctionDetailsLoading', false)
        commit('setAuctionDetailsError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setAuctionDetailsLoading', false)
        commit('setAuctionDetailsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async updateAuction({ commit },payload) {
      try {
        commit('setAuctionUpdateLoading', true)
        const response = await axios.post(`product/update/${payload.id}`,payload.formData);
        commit('setAuctionUpdate', response.data)
        commit('setAuctionUpdateLoading', false)
        commit('setAuctionUpdateError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setAuctionUpdateLoading', false)
        commit('setAuctionUpdateError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async acceptBid ({ commit },payload) {
      commit('setBidLoading', true)
      try {
        const response = await axios.get(`auction/accept_bid/${payload.id}`)
        // console.log(response)
        commit('setBid', response.data)
        commit('setBidLoading', false)
        commit('setBidError', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setBidLoading', false)
        commit('setBidError',error.response.data)
        return Promise.reject(error.response.data)
      }
    }, 
  },
};
