import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
////////////////////////////////////////////////////////////////////////
import "@/packages/bootstrap/config";
////////////////////////////////////////////////////////////////////////
import "@/packages/primeVue/config";
////////////////////////////////////////////////////////////////////////
import { i18n } from "@/packages/vueI18n/config";
////////////////////////////////////////////////////////////////////////
import "@/packages/vuelidate/config";
////////////////////////////////////////////////////////////////////////
import "@/packages/chartJs/config";
////////////////////////////////////////////////////////////////////////
import "@/packages/vuePhoneNumberInput/config";
////////////////////////////////////////////////////////////////////////
import "@/packages/axios/config";
////////////////////////////////////////////////////////////////////////
import "@/packages/moment/config";
////////////////////////////////////////////////////////////////////////
import "@/mixins/global/index";
// global mixin variables (or functions) which is created in each single component or .vue file
////////////////////////////////////////////////////////////////////////

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
