<template>
  <div class="sales-traffic-brief" v-if="statistics">
      <div class="row my-5">
        <div class="col-12">
          <h6>
            <span>{{ $t('heading')+' '}}</span> 
            <span class="custom-title-circle">{{ $t('headingHighlight') }}</span>
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xl-3 my-2" v-for="record in records" :key="record.id">
          <div class="record-wrapper w-100 h-100 pt-4 px-3 d-flex align-items-center">
            <div class="record flex-grow-1">
                <div class="record-details">
                  <h5>{{ record[locale] }}</h5>
                  <div class="d-flex align-items-center justify-content-end">
                      <h5 class="available my-2">{{ record.value }} <small v-if="record.id==4">{{$t('currency')}}</small></h5>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<i18n src="@/lang/global/global.json"></i18n>
<i18n src="@/lang/home/brief.json"></i18n>

<script>
export default {
name: 'SalesTrafficBrief',

data() {
  return {
  };
},
computed:{
    mainStaticData () {
      return this.$store.getters['mainStaticData/mainStaticData']
    },
    statistics () {
      if(this.mainStaticData)
        return this.$store.getters['mainStaticData/mainStaticData']["data"]["trading_traffic"]
      else return false
    },
    records(){
      return [
      {en:"Total Quantity Offered",ar:"إجمالي كمية المعروض",value:this.statistics.quantity_available,id:1},
      {en:"Total Quantity Sold",ar:"إجمالي كمية المباعة",value:this.statistics.quantity_sold,id:2},
      {en:"Types of International Market Supply",ar:"أنواع السوق دولي المعروض",value:this.statistics.international_market,id:3},
      {en:"Total Value - Sales",ar:"إجمالي قمية - المبيعات",value:this.statistics.total_sales,id:4},
    ]
  },
},
mounted() {
  
},

methods: {
    buy(){

    },
},
};
</script>

<style lang="scss" scoped>
.sales-traffic-brief{
  .custom-title-circle{
    padding: 0.5rem 0.25rem;
    border: 3px solid $primary;
    border-radius: 50%;
  }
  .record-wrapper{
    color: white;
    aspect-ratio: 4/1;
    background:$secondary;
    border-radius: 1rem;
    .record{ 
      .record-details{
        background-image: url('@/assets/home/sales1.svg');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: right bottom;
        .available{
          color: $primary;
        }
      }
    }

  }
}
</style>