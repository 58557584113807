<template>
    <div class="special-products" v-if="specialsList">
        <div class="row my-5">
          <div class="col-12">
            <div class="heading d-flex justify-content-between">
              <h6>
                <span>{{ $t('heading')+' '}}</span> 
                <span class="custom-title-circle">{{ $t('headingHighlight') }}</span>
              </h6>
              <router-link :to="{'name':'specialProducts'}" class="nav-link text-muted">
                <span>{{ $t('buttonLabels.WatchMore') }}</span>
                <img src="@/assets/home/fish5.svg" alt="" class="mx-1">
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="specialsList.length>0">
          <div class="row my-5">
              <div class="col-md-6 col-xl-4 my-2" v-for="product in specialsList" :key="product.id">
                <div class="item-wrapper" 
                :style="{ 'background-image': 'url('+mediaURL +'products/'+ product.image + ')' }">
                  <div class="product">
                      <!-- <div class="product-details text-center">
                        <h4>{{ product.name }}</h4>
                        <h6>
                          <span>{{$t('product.availableQuantity')}}</span>
                          <span class="mx-2">{{ product.quantity }}</span>
                          <span>{{ $t('weight') }}</span>
                        </h6>
                      </div> -->
                      <div class="product-details text-center my-4">
                        <h4 v-if="locale=='ar' && product.translations[0]['name']">
                          {{ product.translations[0]["name"] }}
                        </h4>
                        <h4 v-else-if="locale=='en' && product.translations[1]['name']">
                          {{ product.translations[1]["name"] }}
                        </h4>
                        <h4 v-else>{{ product.name }}</h4>
                        <h6>
                          <span>{{$t('product.availableQuantity')}}</span>
                          <span class="mx-2">{{ product.quantity }}</span>
                          <span>{{ $t('weight') }}</span>
                        </h6>
                      </div>
                      <div class="item-action d-flex align-items-center justify-content-center flex-wrap gap-3 my-4">
                        <div>
                          <span class="d-flex justify-content-center align-items-center favorite" @click="toggleFavorite(product.id,$event)">
                            <i class="pi fs-4" :class="[product.is_fav?'pi-heart-fill is-favorite':'pi-heart']"></i>
                          </span>
                          <ConfirmPopup></ConfirmPopup>
                        </div>
                          <span class="d-flex justify-content-center align-items-center gap-2 price">
                            {{ product.price }} <small>{{ $t('currency') }}</small></span>
                            <div>
                              <Button icon="pi pi-cart-plus" class="" @click="addToCart(product,$event)" />
                              <ConfirmPopup></ConfirmPopup>
                            </div>                              
                            <div>
                              <Button :label="$t('buy')" class="px-5" @click="buy(product)" />
                            </div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
</template>
<i18n src="@/lang/global/global.json"></i18n>
<i18n src="@/lang/home/special.json"></i18n>
<script>
export default {
  name: "SpecialProducts",

  data() {
    return {};
  },
  computed: {
    mainStaticData() {
      return this.$store.getters["mainStaticData/mainStaticData"];
    },
    mediaURL() {
      return this.$store.getters["urls/mediaURL"];
    },
    specialsList() {
      if (this.mainStaticData)
        return this.$store.getters["mainStaticData/mainStaticData"]["data"][
          "specials"
        ];
      else return [];
    },
    isAuthenticated() {
      return this.$store.getters["userStatus/isAuthenticated"];
    }
  },
  mounted() {},

  methods: {
    buy(product) {
      this.$router.push({
        name: "productDetails",
        params: { productId: product.id }
      });
    },
    async toggleFavorite(id, event) {
      if (this.isAuthenticated) {
        try {
          await this.$store
            .dispatch("favorite/toggleFavorite", { id: id })
            .then(() => {
              this.specialsList.forEach(product => {
                if (product.id == id) {
                  product.is_fav = !product.is_fav;
                  return;
                }
              });
            });
          // Do something with the loaded data
        } catch (error) {
          console.error(error);
        }
      } else {
        let message = {
          en: "You need to be logged in to continue",
          ar: "تحتاج الي تسجيل الدخول للاستمرار"
        };
        this.$confirm.require({
          target: event.currentTarget,
          message: message[this.locale],
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.$router.push({
              name: "signIn",
              query: { redirect: this.$route.fullPath }
            });
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      }
    },
    addToCart(product, event) {
      if (this.isAuthenticated) {
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
        // prevent adding the same product twice
        if (cart.some(item => item.id === product.id)) {
          this.$toast.add({
            severity: 'info',
            summary: 'Shpping Cart',
            detail: 'Product already exists in cart',
            life: 3000
          });
          // console.log("Product already exists in cart");
          return;
        }
        // Add product to cart
        product['cart_quantity'] = 1;
        cart.push(product);
        // Update local storage
        localStorage.setItem('cart', JSON.stringify(cart));
        this.$store.dispatch('cart/setCart', cart);
        this.$toast.add({
          severity: 'success',
          summary: 'Shpping Cart',
          detail: 'Product added to Cart Successfully',
          life: 3000
        });
      } else {
        let message = {
          en: 'You need to be logged in to continue',
          ar: 'تحتاج الي تسجيل الدخول للاستمرار'
        };
        this.$confirm.require({
          target: event.currentTarget,
          message: message[this.locale],
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.$router.push({
              name: 'signIn',
              query: { redirect: this.$route.fullPath }
            });
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });
      }
    }    
  }
};
</script>

<style lang="scss" scoped>
.special-products {
  .custom-title-circle {
    padding: 0.5rem 0.25rem;
    border: 3px solid $primary;
    border-radius: 50%;
  }
  .item-wrapper {
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    aspect-ratio: 4/3;
    // background-image: url('@/assets/home/special-product-img.png');
    background-size: cover;
    background-position: center center;
    border-radius: 1rem;
    &:hover {
      cursor: pointer;
      transform: translateY(10px);
      box-shadow: 0px 8px 24px rgba($primary, 0.1);
    }
    transition: all 0.5s ease;
    .product {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      // background: rgba(#000000,0.05);
      background: rgba($secondary, 0.3);
      backdrop-filter: blur(1px);
      border-radius: 0 0 1rem 1rem;
      z-index: 1;
      color: white;
      .product-details {
      }
      .item-action {
        .price {
          border: 1px solid white;
          padding: 0.5rem;
          border-radius: 3px;
          font-weight: 700;
        }
        .favorite {
          border: 1px solid white;
          padding: 0.5rem;
          border-radius: 3px;
          font-weight: 700;
          transition: color 0.7s ease-in-out;
          cursor: pointer;
          .is-favorite {
            color: #ff4500;
          }
        }
        .favorite:has(.is-favorite) {
          border-color: #ff4500;
        }
      }
    }
  }
}
</style>