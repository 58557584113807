import axios from 'axios'
export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    user: null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    error: null,
    ///////////
    serverResponse: null,
    serverError: false,
    serverLoading: false
  },
  // get the currant state value
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    getUser: state => state.user,
    getToken: state => state.token,
    getError: state => state.error,
    ///////////////////////////////////
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_AUTHENTICATION_STATUS(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    /////////////////////////////////////////
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    }
  },
  // use to perform un asynchronous tasks
  actions: {
    async getUserProfile({ commit }, formData) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.get('profile');
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_AUTHENTICATION_STATUS', true);
        commit('SET_USER', response.data.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR', false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', error.response.data.errors);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
    setUserStatus({ commit, dispatch }, response) {
      if (response.success) {
        commit('SET_AUTHENTICATION_STATUS', true);
        commit('SET_USER', response.data.user);
        commit('SET_TOKEN', response.data.token);
        localStorage.setItem('token', response.data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      }
      else {
        dispatch('signOut')
        // commit('SET_ERROR', error.data.errors);
        // commit('SET_AUTHENTICATION_STATUS', false);
      }
    },
    signOut({ commit }) {
      localStorage.removeItem('token')
      localStorage.removeItem('beharToken')
      commit('SET_TOKEN', null);
      commit('SET_AUTHENTICATION_STATUS', false);
      commit('SET_USER', null);
    }
  },
};
