import Vue from "vue";
import Vuex from "vuex";
//////////////////////////////////////
import urls from './modules/urls'
//////////////////////////////////////
import search from './modules/search'
//////////////////////////////////////
import contactUs from './modules/contact/contactUs'
//////////////////////////////////////
import news from './modules/news/news'
//////////////////////////////////////
import signUp from './modules/auth/signUp'
import signIn from './modules/auth/signIn'
import userStatus from './modules/auth/userStatus'
import permissions from './modules/auth/permissions'
import forgotPassword from './modules/auth/forgotPassword'
/////////////////////////////////////////////
import account from './modules/profile/account'
import notifications from './modules/profile/notifications'
import addProduct from './modules/profile/addProduct'
import myProducts from './modules/profile/myProducts'
import addAuction from './modules/profile/addAuction'
import myAuctions from "./modules/profile/myAuctions";
import wallet from './modules/profile/wallet'
import sellOrders from './modules/profile/sellOrders'
import purchaseOrders from './modules/profile/purchaseOrders'
import addRequest from './modules/profile/addRequest'
import myRequests from './modules/profile/myRequests'
import unavailableSellOrders from './modules/profile/unavailableSellOrders'
import unavailablePurchaseOrders from './modules/profile/unavailablePurchaseOrders'
import gallery from './modules/profile/gallery'
/////////////////////////////////////////////
import favorite from './modules/favorite/favorite'
/////////////////////////////////////////////
/////////////////////////////////////////////
import cart from './modules/cart/cart'
/////////////////////////////////////////////
import mainStaticData from './modules/mainStaticData'
import bestSeller from './modules/bestSeller'
/////////////////////////////////////////////
import localMarket from './modules/local/localMarket'
import confirmation from './modules/local/confirmation'
import order from './modules/local/order'
/////////////////////////////////////////////
import internationalMarket from './modules/international/internationalMarket'
// import confirmation from './modules/international/confirmation'
import auctions from './modules/auctions/auctions'
import auctionConfirmation from './modules/auctions/confirmation'
/////////////////////////////////////////////
import requests from './modules/requests/requests'
import addOffer from './modules/requests/addOffer'
import confirmOffer from './modules/requests/confirmOffer'
///////////////////////////////////////////////////////////////
////////////////////// Behar //////////////////////////////////
import behar from './modules/behar/behar'



Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    urls,
    ////////////
    search,
    ////////////
    contactUs,
    ////////////
    news,
    ////////////
    signUp,
    signIn,
    userStatus,
    permissions,
    forgotPassword,
    ////////////
    account,
    notifications,
    addProduct,
    myProducts,
    addAuction,
    myAuctions,
    wallet,
    sellOrders,
    sellOrders,
    purchaseOrders,
    addRequest,
    myRequests,
    unavailableSellOrders,
    unavailablePurchaseOrders,
    gallery,
    ////////////
    favorite,
    ////////////
    ////////////
    cart,
    ////////////
    mainStaticData,
    bestSeller,
    ////////////
    localMarket,
    confirmation,
    order,
    ////////////
    internationalMarket,
    // confirmation
    ////////////
    auctions,
    auctionConfirmation,
    ////////////
    requests,
    addOffer,
    confirmOffer,
    ///////behar ///////////////////
    behar,
  },
});
