import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from '@/store'

//*! requireAuth Navigation Guard //
const requireAuth = async (to, from, next) => {
  try {
    // wait for the store to initialize
    await store.dispatch('userStatus/getUserProfile')
    // check if the user is authenticated
    if (!store.getters['userStatus/isAuthenticated'] || !store.getters['userStatus/getToken']) {
      next({
        name: 'signIn',
        query: { redirect: to.fullPath }
      })
    }
    next()
  }
  catch (err) {
    next({
      name: 'signIn',
      query: { redirect: to.fullPath }
    })
  }
};

//*! sellerPermission Navigation Guard //
const requirePermission = (to, from, next) => {
  let user = store.getters['userStatus/getUser']
  let permissions = store.getters['permissions/getPermissions']["local_buyer"]
  // check if the user is authenticated
  if (!user) {
    next({
      name: 'signIn',
      query: { redirect: to.fullPath }
    })
  } else {
    if (user.type) {
      permissions = store.getters['permissions/getPermissions'][user.type]
      if (permissions.canView.includes(to.name))
        next()
      else
        next({ name: 'home' })
    }
    else {
      if (permissions.canView.includes(to.name))
        next()
      else
        next({ name: 'home' })
    }
  }
}
const combinedBeforeEnter = (to, from, next) => {
  requireAuth(to, from, () => {
    requirePermission(to, from, next);
  });
};
// const requirePermission =  (to, from, next) => {
//   console.log(to)
//   let user = store.getters['userStatus/getUser']
//   // check if the user is authenticated
//   if (!user) {
//     next({
//       name: 'signIn',
//       query: { redirect: to.fullPath }
//     })
//   } else {
//     let requiredList = ['fisherman','local_seller','international_seller']
//     if(requiredList.includes(user.type))
//       next()
//     else 
//      next({
//       name: 'account',
//      })
//   }
// }





Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    alias: '/',
    name: "home",
    component: HomeView,
  },
  {
    path: "/local-market",
    name: "localMarket",
    component: () => import("@/views/LocalMarketView.vue"),
    children: [
      {
        path: ':productId',
        name: 'productDetails',
        component: () => import("@/components/localMarketView/ProductDetails.vue"),
        children: [
          {
            path: 'confirmation',
            name: 'productConfirmation',
            component: () => import("@/components/localMarketView/ProductConfirmation"),
          }
        ]
      }
    ]
  },
  {
    path: "/special-products",
    name: "specialProducts",
    component: () => import("@/views/SpecialProductsView.vue"),
    children: [
      {
        path: ':productId',
        name: 'productDetails',
        component: () => import("@/components/localMarketView/ProductDetails.vue"),
        children: [
          {
            path: 'confirmation',
            name: 'productConfirmation',
            component: () => import("@/components/localMarketView/ProductConfirmation"),
          }
        ]
      }
    ]
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import("@/views/CartView.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/international-market",
    name: "internationalMarket",
    component: () => import("@/views/InternationalMarketView02.vue"),
    redirect: { name: 'internationalMarketMain' },
    children: [
      {
        path: "",
        name: "internationalMarketMain",
        component: () => import("@/components/internationalMarketView/Main.vue"),
      },
      {
        path: "behar-market",
        name: "beharMarket",
        component: () => import("@/views/BeharMarketView.vue"),
        beforeEnter: combinedBeforeEnter,
        redirect: { name: 'beharMarketMain' },
        children: [
          {
            path: "",
            name: "beharMarketMain",
            component: () => import("@/components/behar/Main.vue"),
          },
          {
            path: "my-demands",
            name: "myDemands",
            component: () => import("@/components/behar/MyDemands.vue"),
          }
        ],
      }
    ]
  },
  // {
  //   path: "/international-market",
  //   name: "internationalMarket",
  //   component: () => import("@/views/InternationalMarketView.vue"),
  // },
  {
    path: "/auctions",
    name: "auctions",
    component: () => import("@/views/AuctionView.vue"),
    children: [
      {
        path: ':auctionId',
        name: 'auctionDetails',
        component: () => import("@/components/auctionView/AuctionDetails.vue"),
        children: [
          {
            path: 'confirmation',
            name: 'auctionConfirmation',
            component: () => import("@/components/auctionView/AuctionConfirmation.vue"),
          }
        ]
      }
    ]
  },
  // {
  //   path: "/requests",
  //   name: "requests",
  //   component: () => import("@/views/RequestsView.vue"),
  //   children:[
  //     {
  //       path: ':requestId',
  //       name: 'requestDetails',
  //       component: () => import("@/components/requestsView/RequestDetails.vue"),
  //       children:[
  //         {
  //           path: 'addOffer',
  //           name: 'addOffer',
  //           component: () => import("@/components/requestsView/AddOffer.vue"),
  //         }
  //       ]  
  //     }
  //   ]  
  // },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/NewsView.vue"),
    children: [
      {
        path: ':newsId',
        name: 'newsDetails',
        component: () => import("@/components/newsView/NewsDetails.vue"),
        props: true
      }
    ]
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import("@/views/ContactUsView.vue"),
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: () => import("@/views/AboutUsView.vue"),
  },
  {
    path: "/auth",
    name: "authentication",
    component: () => import("@/views/AuthView.vue"),
    children: [
      {
        alias: "/auth",
        path: "sign-in",
        name: "signIn",
        component: () => import("@/components/authView/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "signUp",
        component: () => import("@/components/authView/SignUp.vue"),
      },
      {
        path: "forgot-password",
        name: "forgotPassword",
        component: () => import("@/components/authView/ForgotPassword.vue"),
      },
      {
        path: "check-code",
        name: "checkCode",
        component: () => import("@/components/authView/CheckCode.vue"),
      },
      {
        path: "reset-password",
        name: "resetPassword",
        component: () => import("@/components/authView/ResetPassword.vue"),
      },
    ]
  },
  {
    path: "/profile",
    name: "profile",
    redirect: '/profile/account',

    component: () => import("@/views/ProfileView.vue"),
    beforeEnter: requireAuth,
    children: [
      {
        // alias:"/profile",
        path: "account",
        name: "account",
        component: () => import("@/components/profileView/Account.vue"),
      },
      {
        path: "wallet",
        name: "wallet",
        component: () => import("@/components/profileView/Wallet.vue"),
      },
      {
        path: "sell-orders",
        name: "sellOrders",
        component: () => import("@/components/profileView/SellOrders.vue"),
        beforeEnter: requirePermission,
        children: [
          {
            path: ':orderId',
            name: 'sellOrderDetails',
            component: () => import("@/components/profileView/sellOrders/SellOrderDetails.vue"),
            props: true
          }
        ]
      },
      {
        path: "purchase-orders",
        name: "purchaseOrders",
        component: () => import("@/components/profileView/PurchaseOrders.vue"),
        beforeEnter: requirePermission,
        children: [
          {
            path: ':orderId',
            name: 'purchaseOrderDetails',
            component: () => import("@/components/profileView/purchaseOrders/PurchaseOrderDetails.vue"),
            props: true
          }
        ]
      },
      // {
      //   path: "unavailable-sell-orders",
      //   name: "unavailableSellOrders",
      //   component: () => import("@/components/profileView/UnavailableSellOrders.vue"),
      //    beforeEnter:requirePermission,
      //   children:[
      //     {
      //       path: ':orderId',
      //       name: 'unavailableSellOrderDetails',
      //       component: () => import("@/components/profileView/unavailableSellOrders/SellOrderDetails.vue"),
      //       props: true
      //     }
      //   ]
      // },
      // {
      //   path: "unavailable-purchase-orders",
      //   name: "unavailablePurchaseOrders",
      //   component: () => import("@/components/profileView/UnavailablePurchaseOrders.vue"),
      //    beforeEnter:requirePermission,
      //   children:[
      //     {
      //       path: ':orderId',
      //       name: 'unavailablePurchaseOrderDetails',
      //       component: () => import("@/components/profileView/unavailablePurchaseOrders/PurchaseOrderDetails.vue"),
      //       props: true
      //     }
      //   ]
      // },
      {
        path: "my-products",
        name: "myProducts",
        component: () => import("@/components/profileView/MyProducts.vue"),
        beforeEnter: requirePermission,
        children: [
          {
            path: ':productId',
            name: 'myProductDetails',
            component: () => import("@/components/profileView/products/MyProductDetails.vue"),
          }
        ],
      },
      {
        path: "add-product",
        name: "addProduct",
        component: () => import("@/components/profileView/AddProduct.vue"),
        beforeEnter: requirePermission,
      },
      {
        path: "my-auctions",
        name: "myAuctions",
        component: () => import("@/components/profileView/MyAuctions"),
        children: [
          {
            path: ':auctionId',
            name: 'myAuctionDetails',
            component: () => import("@/components/profileView/auctions/MyAuctionDetails.vue"),
          }
        ],
        beforeEnter: requirePermission,
      },
      {
        path: "add-auction",
        name: "addAuction",
        component: () => import("@/components/profileView/AddAuction.vue"),
        beforeEnter: requirePermission,
      },
      // {
      //   path: "my-requests",
      //   name: "myRequests",
      //   component: () => import("@/components/profileView/MyRequests.vue"),
      // beforeEnter:requirePermission,
      //   children:[
      //     {
      //       path: ':requestId',
      //       name: 'myRequestDetails',
      //       component: () => import("@/components/profileView/requests/MyRequestDetails.vue"),
      //       children:[
      //         {
      //           path: 'offer/:offerId',
      //           name: 'offerConfirmation',
      //           component: () => import("@/components/profileView/requests/OfferConfirmation.vue"),
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   path: "add-request",
      //   name: "addRequest",
      //   component: () => import("@/components/profileView/AddRequest.vue"),
      // beforeEnter:requirePermission,
      // },
      {
        path: "my-favorites",
        name: "myFavorites",
        component: () => import("@/components/profileView/MyFavorites.vue"),
      },
      // after handling all routes it comes last to not found page .
      {
        path: '/:notfound(.*)',
        redirect: '/',
        // component:NotFound
      }
    ]
  },
];


const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
