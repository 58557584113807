import { render, staticRenderFns } from "./NewsTicker.vue?vue&type=template&id=44c353f0&scoped=true"
import script from "./NewsTicker.vue?vue&type=script&lang=js"
export * from "./NewsTicker.vue?vue&type=script&lang=js"
import style0 from "./NewsTicker.vue?vue&type=style&index=0&id=44c353f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c353f0",
  null
  
)

/* custom blocks */
import block0 from "@/lang/global/global.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5ChomeView%5Cfish%5CNewsTicker.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports