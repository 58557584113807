import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=67f763e2&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=67f763e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f763e2",
  null
  
)

/* custom blocks */
import block0 from "@/lang/layout/footer.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5Cglobal%5Clayout%5CFooter.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports