<template>
  <!-- <div class="news-ticker" v-if="news.length>0">
    <ul ref="ticker" class="ticker-list">
      <li v-for="item in news" :key="item.id" class="my-2">
        <div class="text-center item-wrapper d-flex justify-center align-items-center mx-4">
          <span class="mx-2">{{ item[locale] }}</span>
          <img :src="mediaURL+info.logo" alt="" class="w-25 h-25">
        </div>
      </li>
    </ul>
  </div> -->


  <div class="d-flex justify-content-between align-items-center breaking-news">
    <marquee behavior="scroll" :direction="locale=='ar'?'left':'right'" onmouseover="this.stop();"
      onmouseout="this.start();" start> 
        <div class="fish-list d-flex gap-4">
          <span class="fish-wrapper d-flex justify-center align-items-center gap-4" v-for="item in news" :key="item.id">
            <span class="d-block mx-2">{{ item[locale] }}</span>
            <img :src="mediaURL+info.logo" alt="" class="d-block">
          </span>
        </div>
    </marquee>
  </div>
</template>

<script>
export default {
  data(){
    return{
      news:[]
      
    }
  },
  computed:{
    mediaURL () {
      return this.$store.getters['urls/mediaURL']
    },
    mainStaticData () {
      return this.$store.getters['mainStaticData/mainStaticData']
    },
    info(){
      if(this.mainStaticData)
        return this.mainStaticData.data.info
      else
        return false
    },
  },
  created(){
    for (let i = 0; i <5; i++){
      this.news.push(
        {en:"Saudi Fish Exchange",ar:"بورصة الأسماك السعودية",logo:"@/assets/logo/lodo-green.svg",id:i+1})
    }
  },
  mounted() {
    // if(this.news.length>0)
    //   this.startTicker()
  },
  methods: {
    // startTicker() {
    //   const ticker = this.$refs.ticker
    //   const items = ticker.querySelectorAll('li')
    //   const itemWidth = items[0].offsetWidth
    //   const screenWidth = window.innerWidth
    //   let currentPosition = 0
    //   let currentItem = 0
    //   if(this.locale=='ar'){
    //     // Set initial position of ticker items
    //     items.forEach(item => {
    //       item.style.transform = `translateX(${currentPosition}px)`
    //       currentPosition += itemWidth
    //     })
        
    //     // Animate ticker items
    //     setInterval(() => {
    //       currentPosition -= 1
    //       items.forEach(item => {
    //         item.style.transform = `translateX(${currentPosition}px)`
    //       })
    //       if (currentPosition < -itemWidth) {
    //         const firstItem = ticker.querySelector('li:first-child')
    //         ticker.appendChild(firstItem)
    //         currentPosition += itemWidth
    //         currentItem++
    //         if (currentItem === items.length) {
    //           // Reset position of ticker when all items have been displayed
    //           currentPosition = 0
    //           currentItem = 0
    //           items.forEach(item => {
    //             item.style.transform = `translateX(${currentPosition}px)`
    //             currentPosition += itemWidth
    //           })
    //         }
    //       }
    //     }, 20)
    //   }
    //   else {
    //       // Reverse the order of the ticker items
    //       const reversedItems = [...items].reverse()
    //       ticker.innerHTML = ''
    //       reversedItems.forEach(item => {
    //         ticker.appendChild(item)
    //       })
          
    //       // Set initial position of ticker items
    //       items.forEach(item => {
    //         item.style.transform = `translateX(${currentPosition}px)`
    //         currentPosition -= itemWidth
    //       })
    //       // Animate ticker items
    //       setInterval(() => {
    //         currentPosition += 1 // Change the direction of the animation
    //         items.forEach(item => {
    //           item.style.transform = `translateX(${currentPosition}px)`
    //         })
    //         if (currentPosition > itemWidth) { // Change the condition for resetting the ticker
    //           const lastItem = ticker.querySelector('li:last-child')
    //           ticker.insertBefore(lastItem, ticker.firstChild)
    //           currentPosition -= itemWidth
    //           currentItem++
    //           if (currentItem === items.length) {
    //             // Reset position of ticker when all items have been displayed
    //             currentPosition = 0
    //             currentItem = 0
    //             items.forEach(item => {
    //               item.style.transform = `translateX(${currentPosition}px)`
    //               currentPosition += itemWidth
    //             })
    //           }
    //         }
    //       }, 20)
    //   }
    // },



    // startTicker() {
    //   const ticker = this.$refs.ticker
    //   const items = ticker.querySelectorAll('li')
    //   const itemWidth = items[0].offsetWidth
    //   const screenWidth = window.innerWidth
    //   let currentPosition = 0
    //   let currentItem = 0
    //   let animationTimeoutId = null // add a variable to keep track of the animation timeout ID
    //   if (this.locale == 'ar') {
    //     // Set initial position of ticker items
    //     items.forEach(item => {
    //       item.style.transform = `translateX(${currentPosition}px)`
    //       currentPosition += itemWidth
    //     })

    //     // Animate ticker items
    //     const animate = () => {
    //       currentPosition -= 1
    //       items.forEach(item => {
    //         item.style.transform = `translateX(${currentPosition}px)`
    //       })
    //       if (currentPosition < -itemWidth) {
    //         const firstItem = ticker.querySelector('li:first-child')
    //         ticker.appendChild(firstItem)
    //         currentPosition += itemWidth
    //         currentItem++
    //         if (currentItem === items.length) {
    //           // Reset position of ticker when all items have been displayed
    //           currentPosition = 0
    //           currentItem = 0
    //           items.forEach(item => {
    //             item.style.transform = `translateX(${currentPosition}px)`
    //             currentPosition += itemWidth
    //           })
    //         }
    //       }
    //       // Set a new timeout for the next animation frame
    //       animationTimeoutId = setTimeout(animate, 20)
    //     }

    //     // Start the animation loop
    //     animate()
    //   } else {
    //     // Reverse the order of the ticker items
    //     const reversedItems = [...items].reverse()
    //     ticker.innerHTML = ''
    //     reversedItems.forEach(item => {
    //       ticker.appendChild(item)
    //     })

    //     // Set initial position of ticker items
    //     items.forEach(item => {
    //       item.style.transform = `translateX(${currentPosition}px)`
    //       currentPosition -= itemWidth
    //     })

    //     // Animate ticker items
    //     const animate = () => {
    //       currentPosition += 1 // Change the direction of the animation
    //       items.forEach(item => {
    //         item.style.transform = `translateX(${currentPosition}px)`
    //       })
    //       if (currentPosition > itemWidth) { // Change the condition for resetting the ticker
    //         const lastItem = ticker.querySelector('li:last-child')
    //         ticker.insertBefore(lastItem, ticker.firstChild)
    //         currentPosition -= itemWidth
    //         currentItem++
    //         if (currentItem === items.length) {
    //           // Reset position of ticker when all items have been displayed
    //           currentPosition = 0
    //           currentItem = 0
    //           items.forEach(item => {
    //             item.style.transform = `translateX(${currentPosition}px)`
    //             currentPosition += itemWidth
    //           })
    //         }
    //       }
    //       // Set a new timeout for the next animation frame
    //       animationTimeoutId = setTimeout(animate, 20)
    //     }

    //     // Start the animation loop
    //     animate()
    //   }

    //   // Stop the animation loop when the component is destroyed
    //   this.$once('hook:beforeDestroy', () => {
    //     clearTimeout(animationTimeoutId)
    //   })
    // },
  }
}
</script>

<style lang="scss" scoped>
// .news-ticker {
//   transform: translateZ(0);
//   width: 100%;
//   height: 100px;
//   overflow: hidden;
//   // background-color: $secondary;
//   z-index: 5;
//   .ticker-list {
//     height: 100%;
//     display: flex;
//     align-items: center;
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     animation: ticker  linear infinite;
//     li {
//     // margin-right: 20px;
//     white-space: nowrap;
//     color: white;
//     opacity: 0.5;
//     .item-wrapper{
//       // border-right: 3px solid rgba($gray,0.1);
//       // padding-right: 1rem;    
//       .price{
//         color: $primary;
//       }
//       .nav-link{
//         &:hover {
//           color: $primary !important;
//         }
//       }
//     }
//   }
//   }
// }
// .ltr.ticker-list{
//   flex-direction: row-reverse;
// }
// @keyframes ticker {
//   0% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// }





.breaking-news{
  padding: 0px 15px;
  background: rgba(0,0,0,.5);
  margin-top: 15px;
  border-radius: 10px;
  // background-color: $secondary;
  // padding: 1rem;
  .fish-list{
    height: 50px;
    .fish-wrapper{
      margin-right: 20px;
      white-space: nowrap;
      color: white;
      // border-right: 3px solid rgba($gray, 0.1);
      // padding-right: 1rem;
      img{
        width:40px;
        height:40px;
      }
      .price {
        color: $primary;
      }
      .nav-link {
        &:hover {
          color: $primary !important;
        }
      }
    }
  }
}
</style>