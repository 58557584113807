import axios from 'axios'
export default {
  namespaced: true,
  state: {
    supplyList: [],
    supplyListLoading: false,
    supplyListError: false,
    ///////////////////////
    upsertResponse: null,
    upsertLoading: false,
    upsertError: null,
  },
  // get the currant state value
  getters: {
    supplyList: state => state.supplyList,
    supplyListLoading: state => state.supplyListLoading,
    supplyListError: state => state.supplyListError,
    ///////////////////////////////////////////////
    upsertResponse: state => state.upsertResponse,
    upsertLoading: state => state.upsertLoading,
    upsertError: state => state.upsertError,
    ///////////////////////////////////////////////
  },
  // use to perform un mutate or change states
  mutations: {
    setSupplyList(state, data) {
      state.supplyList = data
    },
    setSupplyListLoading(state, loading) {
      state.supplyListLoading = loading
    },
    setSupplyListError(state, error) {
      state.supplyListError = error
    },
    ////////////////////
    setUpsertResponse(state, data) {
      state.upsertResponse = data
    },
    setUpsertLoading(state, loading) {
      state.upsertLoading = loading
    },
    setUpsertError(state, error) {
      state.upsertError = error
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    async loadSupply({ commit }, payload) {
      commit('setSupplyListLoading', true)
      try {
        const response = await axios.post('platforms/behar-market/supply/list', payload)
        console.log(response)
        commit('setSupplyList', response.data.data)
        return Promise.resolve(response)
      } catch (error) {
        commit('setSupplyListError', error.response.data)
        return Promise.reject(error)
      }
      finally {
        commit('setSupplyListLoading', false)
      }
    },
    async upsert({ commit }, form) {
      commit('setUpsertLoading', true)
      try {
        const response = await axios.post('platforms/behar-market/supply/add', form);
        console.log(response);
        commit('setUpsertResponse', response.data)
        commit('setUpsertError', null)
        return Promise.resolve(response)
      } catch (error) {
        console.log(error)
        commit('setUpsertError', error.data)
        return Promise.reject(error)
      }
      finally {
        commit('setUpsertLoading', false)
      }
    },
  },
};
