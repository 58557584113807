import axios from 'axios'
export default {
  namespaced: true,
  state: {
    myProducts: null,
    myProductsLoading: false,
    myProductsError: false,
    ////////////////////////////////
    productDetails: null,
    productDetailsLoading: false,
    productDetailsError: false,
    ////////////////////////////////
    productUpdate: null,
    productUpdateLoading: false,
    productUpdateError: false,
  },
  // get the currant state value
  getters: { 
    myProductsData: state => state.myProducts,
    myProductsLoading: state => state.myProductsLoading,
    myProductsError: state => state.myProductsError,
    /////////////////////////////////////////////
    productDetailsData: state => state.productDetails,
    productDetailsLoading: state => state.productDetailsLoading,
    productDetailsError: state => state.productDetailsError,
    /////////////////////////////////////////////
    productUpdateData: state => state.productUpdate,
    productUpdateLoading: state => state.productUpdateLoading,
    productUpdateError: state => state.productUpdateError,

  },
  // use to perform un mutate or change states
  mutations: {
    setMyProducts (state, data) {
      state.myProducts = data
    },
    setMyProductsLoading (state, loading) {
      state.myProductsLoading = loading
    },
    setMyProductsError (state, error) {
      state.myProductsError = error
    },
    //////////////////////////////////////////
    setProductDetails (state, data) {
      state.productDetails = data
    },
    setProductDetailsLoading (state, loading) {
      state.productDetailsLoading = loading
    },
    setProductDetailsError (state, error) {
      state.productDetailsError = error
    },
    //////////////////////////////////////////
    setProductUpdate (state, data) {
      state.productUpdate = data
    },
    setProductUpdateLoading (state, loading) {
      state.productUpdateLoading = loading
    },
    setProductUpdateError (state, error) {
      state.productUpdateError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadMyProducts ({ commit },payload) {
      commit('setMyProductsLoading', true)
      try {
        const response = await axios.get('product/my/local/normal',{params: payload})
        // console.log(response)
        commit('setMyProducts', response.data)
        commit('setMyProductsLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setMyProductsLoading', false)
        commit('setMyProductsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async loadProductDetails({ commit }, payload) {
      try {
        commit('setProductDetailsLoading', true)
        const response = await axios.get(`product/update/${payload.id}`);
        commit('setProductDetails', response.data)
        commit('setProductDetailsLoading', false)
        commit('setProductDetailsError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setProductDetailsLoading', false)
        commit('setProductDetailsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async updateProduct({ commit },payload) {
      try {
        commit('setProductUpdateLoading', true)
        const response = await axios.post(`product/update/${payload.id}`,payload.formData);
        commit('setProductUpdate', response.data)
        commit('setProductUpdateLoading', false)
        commit('setProductUpdateError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setProductUpdateLoading', false)
        commit('setProductUpdateError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
  },
};
