import axios from 'axios'
export default {
  namespaced: true,
  state: {

    // buyer
    // form: {
    //   mobile:"966665544332211",
    //   password:"987654321",
    // },


    // Seller
    // form: {
    //   mobile:"966123456789",
    //   password:"123456789",
    // },

    // intrnational
    // form: {
    //   mobile: "0555555555",
    //   password: "123123123",
    // },




    form: {
      mobile: "",
      password: "",
    },

    serverResponse: null,
    serverError: false,
    serverLoading: false
  },
  // get the currant state value
  getters: {
    getForm: state => state.form,
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_FORM_DATA(state, formData) {
      state.form = formData;
    },
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    }
  },
  // use to perform un asynchronous tasks
  actions: {
    resetForm({ commit }) {
      const form = {
        mobile: "",
        password: "",
      }
      commit('SET_FORM_DATA', form);
    },
    async signIn({ commit }, formData) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.post('login', formData);
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR', false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', {
          errors: [error.response.data.message]
        });
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    }
  },
};
