<template>
    <div class="cart-item d-flex justify-content-between gap-3 p-2 my-2">
        <img class="cart-item-image d-block" :src="mediaURL+'products/'+item.image" />
        <div class="cart-item-details flex-grow-1">
            <h6 class="cart-item-details-name">
              <router-link :to="{name: 'productDetails', params:{productId: item.id}}">
                {{ item.name }}
              </router-link>
            </h6>
            <div class="cart-item-details-qty my-2">
              <InputNumber id="minmax-buttons" v-model="quantity" mode="decimal" showButtons :min="1" :max="parseInt(item.quantity)" @input="updateQuantity" />
              <!-- <label for="minmax-buttons">{{$t('weight')}}</label> -->
            </div>
            <h6 class="cart-item-details-price">{{ item.price }} {{$t('currency')}}</h6>
        </div>
        <div>
          <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined custom-size" @click="deleteFromCart(item)" />
        </div>
    </div>
</template>
<i18n src="@/lang/global/global.json"></i18n>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      quantity: this.item.cart_quantity
    };
  },
  computed: {
    mediaURL() {
      return this.$store.getters["urls/mediaURL"];
    }
  },
  methods: {
    updateQuantity() {
      let cart = JSON.parse(localStorage.getItem("cart")) || [];
      // find index of item in cart
      let index = cart.findIndex(i => i.id === this.item.id);
      if (index !== -1) {
        // update quantity of item in cart
        cart[index]["cart_quantity"] = this.quantity;
        // update local storage
        localStorage.setItem("cart", JSON.stringify(cart));
        this.$store.dispatch("cart/setCart", cart);
        console.log(this.quantity);
      }
    },
    deleteFromCart(item) {
      let cart = JSON.parse(localStorage.getItem("cart")) || [];
      // find index of item in cart
      let index = cart.findIndex(i => i.id === item.id);
      if (index !== -1) {
        // remove item from cart
        cart.splice(index, 1);
        // update local storage
        localStorage.setItem("cart", JSON.stringify(cart));
        this.$store.dispatch("cart/setCart", cart);
        this.$toast.add({
          severity: "warn",
          summary: "Shpping Cart",
          detail: "Product deleted from Cart Successfully",
          life: 3000
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
:deep(.pi) {
  font-size: 0.75rem !important;
}
:deep(.custom-size) {
  padding: 0 !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
}
:deep(#minmax-buttons) {
  // width: 3rem !important;
  text-align: center !important;
  border: 0px !important;
}
.cart-item {
  border-radius: 0.5rem;
  background-color: $gray;
  min-width: 300px !important;
  .cart-item-image {
    width: 6rem;
    height: 6rem;
  }
  .cart-item-details-name {
    a:not(.router-link-exact-active) {
      color: $secondary;
    }
  }
}
</style>