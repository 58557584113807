import axios from 'axios'
export default {
  namespaced: true,
  state: {
    localMarket: null,
    localMarketLoading: false,
    localMarketError: false,
    ////////////////////////////////
    productDetails: null,
    productDetailsLoading: false,
    productDetailsError: false,
  },
  // get the currant state value
  getters: { 
    localMarketData: state => state.localMarket,
    localMarketLoading: state => state.localMarketLoading,
    localMarketError: state => state.localMarketError,
    /////////////////////////////////////////////
    productDetailsData: state => state.productDetails,
    productDetailsLoading: state => state.productDetailsLoading,
    productDetailsError: state => state.productDetailsError,

  },
  // use to perform un mutate or change states
  mutations: {
    setLocalMarket (state, data) {
      state.localMarket = data
    },
    setLocalMarketLoading (state, loading) {
      state.localMarketLoading = loading
    },
    setLocalMarketError (state, error) {
      state.localMarketError = error
    },
    //////////////////////////////////////////
    setProductDetails (state, data) {
      state.productDetails = data
    },
    setProductDetailsLoading (state, loading) {
      state.productDetailsLoading = loading
    },
    setProductDetailsError (state, error) {
      state.productDetailsError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadLocalMarket ({ commit },payload) {
      commit('setLocalMarketLoading', true)
      try {
        const response = await axios.get('products/local',{params: payload})
        // console.log(response)
        commit('setLocalMarket', response.data)
        commit('setLocalMarketLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setLocalMarketLoading', false)
        commit('setLocalMarketError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async loadProductDetails({ commit }, payload) {
      try {
        commit('setProductDetailsLoading', true)
        const response = await axios.get(`products/show/${payload.id}`);
        commit('setProductDetails', response.data)
        commit('setProductDetailsLoading', false)
        commit('setProductDetailsError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setProductDetailsLoading', false)
        commit('setProductDetailsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
  },
};
