import axios from 'axios'
export default {
  namespaced: true,
  state: {
    form: {
      sale_type: "normal",
      city_id: null,
      payment_id: null,
      delivery_method_id: null,
      address: {
        street: "",
        region: "",
        address: "",
        zip_code: "",
        recipient_name: ""
      },
      body: "",
      products: [],
    },
    // form: {
    //   sale_type: "normal",
    //   city_id: 5,
    //   payment_id: 3,
    //   delivery_method_id: 2,
    //   address: {
    //     street: "Benha",
    //     region: "Benha",
    //     address: "Benha",
    //     zip_code: "13511",
    //     recipient_name: "Mahmoud Hamed"
    //   },
    //   body: "Test Create Orders for Cart Api",
    //   products: [],
    // },
    serverResponse: null,
    serverError: false,
    serverLoading: false,
    ////////////////////////////////
    detailsServerLoading: false,
    detailsServerResponse: null,
    detailsServerError: false,
  },
  // get the currant state value
  getters: {
    getForm: state => state.form,
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
    /////////////////////////////////////////
    getDetailsServerLoading: state => state.detailsServerLoading,
    getDetailsServerResponse: state => state.detailsServerResponse,
    getDetailsServerError: state => state.detailsServerError,
  },
  // use to perform un mutate or change states
  mutations: {
    RESET_CONFIRMATION(state) {
      state.serverResponse = null;
      state.serverError = false;
      state.serverLoading = false;
    },
    SET_FORM_DATA(state, formData) {
      state.form = formData;
    },
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    },
    ///////////////////////////////////
    SET_Details_SERVER_LOADING(state, loading) {
      state.detailsServerLoading = loading;
    },
    SET_Details_SERVER_RESPONSE(state, response) {
      state.detailsServerResponse = response;
    },
    SET_Details_SERVER_ERROR(state, error) {
      state.detailsServerError = error;
    },
    ///////////////////////////////////
  },
  // use to perform un asynchronous tasks
  actions: {
    resetConfirmation({ commit }) {
      commit('RESET_CONFIRMATION');
    },
    resetForm({ commit }) {
      const form = {
        sale_type: "normal",
        city_id: null,
        payment_id: null,
        delivery_method_id: null,
        address: {
          street: "",
          region: "",
          address: "",
          zip_code: "",
          recipient_name: ""
        },
        body: "",
        products: [],
      };
      commit('SET_FORM_DATA', form);
    },
    async create({ commit }, payload) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.post(`orders/create`, payload);
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR', null);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', error.response.data.errors);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
    async loadProductDetails({ commit }, payload) {
      try {
        commit('SET_Details_SERVER_LOADING', true);
        const response = await axios.get(`orders/create/${payload.productId}`);
        commit('SET_Details_SERVER_RESPONSE', response.data);
        commit('SET_Details_SERVER_LOADING', false);
        commit('SET_Details_SERVER_ERROR', false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_Details_SERVER_ERROR', error.response.data.errors);
        commit('SET_Details_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
  },
};
