<template>
  <div class="statistical-charts" v-if="mainStaticData">
    <div class="row my-5">
      <div class="col-md-4">
        <div class="heading">
          <h4 class="my-3 text-center">{{ $t('bestSeller') }}</h4>
        </div>
        <div class="top-seller">
          <div v-if="active==0">
            <Chart 
              v-if="dailyChartsTopSalesData"
              type="doughnut" 
              :data="dailyChartsTopSalesData" 
              :options="pieChartOptions" 
            />
            <div class="no-results text-center" v-else>
              <div class="img-wrapper">
                <img src="@/assets/global/no-results.svg" alt="">
              </div>
              <h4>{{ $t('noResultsMsg') }}</h4>
            </div>
          </div>

          <div v-if="active==1">
            <Chart 
              v-if="weeklyChartsTopSalesData"
              type="doughnut" 
              :data="weeklyChartsTopSalesData" 
              :options="pieChartOptions" 
            />
            <div class="no-results text-center" v-else>
              <div class="img-wrapper">
                <img src="@/assets/global/no-results.svg" alt="">
              </div>
              <h4>{{ $t('noResultsMsg') }}</h4>
            </div>
          </div>

          <div v-if="active==2">
            <Chart 
              v-if="monthlyChartsTopSalesData"
              type="doughnut" 
              :data="monthlyChartsTopSalesData" 
              :options="pieChartOptions" 
            />
            <div class="no-results text-center" v-else>
              <div class="img-wrapper">
                <img src="@/assets/global/no-results.svg" alt="">
              </div>
              <h4>{{ $t('noResultsMsg') }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="heading d-flex justify-content-between align-items-center">
          <h4 class="mb-0">{{ $t('sales') }}</h4>
          <SelectButton v-model="active" :options="tabs" :optionLabel="locale" optionValue="id" class="d-flex"  /> 
          <!-- <TabMenu :model="tabsAr" :activeIndex.sync="active" v-if="locale=='ar'"   />
          <TabMenu :model="tabsEn" :activeIndex.sync="active" fa-flip-horizontal v-else /> -->
        </div>
        <div class="sales">
          <div v-if="active==0">
            <Chart 
              v-if="dailyChartsSalesData"
              type="line" 
              :data="dailyChartsSalesData" 
              :options="lineChartOptions" 
            />
            <div class="no-results text-center" v-else>
              <div class="img-wrapper">
                <img src="@/assets/global/no-results.svg" alt="">
              </div>
              <h4>{{ $t('noResultsMsg') }}</h4>
            </div>
          </div>

          <div v-if="active==1">
            <Chart 
              v-if="weeklyChartsSalesData"
              type="line" 
              :data="weeklyChartsSalesData" 
              :options="lineChartOptions" 
            />
            <div class="no-results text-center" v-else>
              <div class="img-wrapper">
                <img src="@/assets/global/no-results.svg" alt="">
              </div>
              <h4>{{ $t('noResultsMsg') }}</h4>
            </div>
          </div>

          <div v-if="active==2">
            <Chart 
              v-if="monthlyChartsSalesData"
              type="line" 
              :data="monthlyChartsSalesData" 
              :options="lineChartOptions" 
            />
            <div class="no-results text-center" v-else>
              <div class="img-wrapper">
                <img src="@/assets/global/no-results.svg" alt="">
              </div>
              <h4>{{ $t('noResultsMsg') }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<i18n src="@/lang/global/global.json"></i18n>
<i18n src="@/lang/home/charts.json"></i18n>
<script>
export default {
  name: "StatisticalCharts",

  data() {
    return {
      active: 2,
      tabs: [
        {
          id: 0,
          en: "Daily",
          ar: "يوميا"
        },
        {
          id: 1,
          en: "Weekly",
          ar: "اسبوعيا"
        },
        {
          id: 2,
          en: "Monthly",
          ar: "شهريا"
        }
      ],
      tabsAr: [{ label: "يوميا" }, { label: "اسبوعيا" }, { label: "شهريا" }],
      tabsEn: [{ label: "Daily" }, { label: "Weekly" }, { label: "Monthly" }],
      linesLabels: {
        en: {
          quantityAvailable: "Stored Quantity",
          quantityProgress: "Quantity in Process",
          quantitySold: "Quantity Sold"
        },
        ar: {
          quantityAvailable: "الكمية المخزنة",
          quantityProgress: "كمية تحت الاجراء",
          quantitySold: "الكمية المباعة"
        }
      },
      pieChartOptions: {
        plugins: {
          datalabels: false,
          legend: {
            display: true,
            position: "bottom",
            align: "center",
            labels: {
              color: "#184341"
              // boxWidth:100,
              // boxHeight:10,
              // usePointStyle:true,
              // fontSize: 18
            }
          }
        }
      },
      lineChartOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057"
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: "#495057"
            },
            grid: {
              color: "#ebedef"
            }
          },
          y: {
            ticks: {
              color: "#495057"
            },
            grid: {
              color: "#ebedef"
            }
          }
        }
      }
    };
  },
  computed: {
    mainStaticData() {
      return this.$store.getters["mainStaticData/mainStaticData"];
    },
    dailyCharts() {
      if (this.mainStaticData)
        return this.$store.getters["mainStaticData/mainStaticData"]["data"][
          "charts"
        ]["day"];
      else return false;
    },
    dailyChartsSalesData() {
      let labels = [];
      let quantityAvailable = [];
      let quantityProgress = [];
      let quantitySold = [];
      if (this.dailyCharts["data"]) {
        if (this.dailyCharts["data"].length) {
          this.dailyCharts["data"].forEach(el => {
            labels.push(el.slug);
            quantityAvailable.push(el.quantity_available);
            quantityProgress.push(el.quantity_progress);
            quantitySold.push(el.quantity_sold);
          });
          let data = {
            labels,
            datasets: [
              {
                label: this.linesLabels[this.locale].quantityAvailable,
                data: quantityAvailable,
                fill: false,
                borderColor: "#0075B8",
                tension: 0.4
              },
              {
                label: this.linesLabels[this.locale].quantityProgress,
                data: quantityProgress,
                fill: false,
                borderColor: "#F55E30",
                tension: 0.4
              },
              {
                label: this.linesLabels[this.locale].quantitySold,
                data: quantitySold,
                fill: false,
                borderColor: "#6EAF46",
                tension: 0.4
              }
            ]
          };
          return data;
        } else return false;
      } else return false;
    },
    dailyChartsTopSalesData() {
      let labels = [];
      let dataSet = [];
      if (this.dailyCharts["top_sales"]) {
        if (this.dailyCharts["top_sales"].length) {
          this.dailyCharts["top_sales"].forEach(el => {
            labels.push(el.slug);
            dataSet.push(el.average_price);
          });
          let data = {
            labels,
            datasets: [
              {
                data: dataSet,
                backgroundColor: ["#6EAF46", "#929292", "#36A2EB"],
                hoverBackgroundColor: ["#6EAF46", "#929292", "#36A2EB"],
                borderWidth: 0
              }
            ]
          };
          return data;
        } else return false;
      } else return false;
    },
    weeklyCharts() {
      if (this.mainStaticData)
        return this.$store.getters["mainStaticData/mainStaticData"]["data"][
          "charts"
        ]["week"];
      else return false;
    },
    weeklyChartsSalesData() {
      let labels = [];
      let quantityAvailable = [];
      let quantityProgress = [];
      let quantitySold = [];
      if (this.weeklyCharts["data"]) {
        if (this.weeklyCharts["data"].length) {
          this.weeklyCharts["data"].forEach(el => {
            labels.push(el.slug);
            quantityAvailable.push(el.quantity_available);
            quantityProgress.push(el.quantity_progress);
            quantitySold.push(el.quantity_sold);
          });
          let data = {
            labels,
            datasets: [
              {
                label: this.linesLabels[this.locale].quantityAvailable,
                data: quantityAvailable,
                fill: false,
                borderColor: "#0075B8",
                tension: 0.4
              },
              {
                label: this.linesLabels[this.locale].quantityProgress,
                data: quantityProgress,
                fill: false,
                borderColor: "#F55E30",
                tension: 0.4
              },
              {
                label: this.linesLabels[this.locale].quantitySold,
                data: quantitySold,
                fill: false,
                borderColor: "#6EAF46",
                tension: 0.4
              }
            ]
          };
          return data;
        } else return false;
      } else return false;
    },
    weeklyChartsTopSalesData() {
      let labels = [];
      let dataSet = [];
      if (this.weeklyCharts["top_sales"]) {
        if (this.weeklyCharts["top_sales"].length) {
          this.weeklyCharts["top_sales"].forEach(el => {
            labels.push(el.slug);
            dataSet.push(el.average_price);
          });
          let data = {
            labels,
            datasets: [
              {
                data: dataSet,
                backgroundColor: ["#6EAF46", "#929292", "#36A2EB"],
                hoverBackgroundColor: ["#6EAF46", "#929292", "#36A2EB"],
                borderWidth: 0
              }
            ]
          };
          return data;
        } else return false;
      } else return false;
    },
    monthlyCharts() {
      if (this.mainStaticData)
        return this.mainStaticData["data"]["charts"]["month"];
      else return false;
    },
    monthlyChartsSalesData() {
      let labels = [];
      let quantityAvailable = [];
      let quantityProgress = [];
      let quantitySold = [];
      if (this.monthlyCharts["data"]) {
        if (this.monthlyCharts["data"].length) {
          this.monthlyCharts["data"].forEach(el => {
            labels.push(el.slug);
            // labels.push(el.name);
            quantityAvailable.push(el.quantity_available);
            quantityProgress.push(el.quantity_progress);
            quantitySold.push(el.quantity_sold);
          });
          let data = {
            labels,
            datasets: [
              {
                label: this.linesLabels[this.locale].quantityAvailable,
                data: quantityAvailable,
                fill: false,
                borderColor: "#0075B8",
                tension: 0.4
              },
              {
                label: this.linesLabels[this.locale].quantityProgress,
                data: quantityProgress,
                fill: false,
                borderColor: "#F55E30",
                tension: 0.4
              },
              {
                label: this.linesLabels[this.locale].quantitySold,
                data: quantitySold,
                fill: false,
                borderColor: "#6EAF46",
                tension: 0.4
              }
            ]
          };
          return data;
        } else return false;
      } else return false;
    },
    monthlyChartsTopSalesData() {
      let labels = [];
      let dataSet = [];
      if (this.monthlyCharts["top_sales"]) {
        if (this.monthlyCharts["top_sales"].length) {
          this.monthlyCharts["top_sales"].forEach(el => {
            // labels.push(el.name);
            labels.push(el.slug);
            dataSet.push(el.average_price);
          });
          let data = {
            labels,
            datasets: [
              {
                data: dataSet,
                backgroundColor: ["#6EAF46", "#929292", "#36A2EB"],
                hoverBackgroundColor: ["#6EAF46", "#929292", "#36A2EB"],
                borderWidth: 0
              }
            ]
          };
          return data;
        } else return false;
      } else return false;
    }
  },
  mounted() {},

  methods: {}
};
</script>

<style lang="scss" scoped>
</style>