import axios from 'axios'
export default {
  namespaced: true,
  state: {
    form: {
      name:"",
      email:"",
      subject:"",
      message:"",
    },
    serverResponse: null,
    serverError: false,
    serverLoading: false
  },
  // get the currant state value
  getters: { 
    getForm: state => state.form,
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_FORM_DATA(state, formData) {
      state.form = formData;
    },
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    }
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    resetForm({ commit }){
      const form = {
        name:"",
        email:"",
        subject:"",
        message:"",
      }
      commit('SET_FORM_DATA',form);
    },
    async contactUs({ commit }, formData) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.post('contact/create', formData);
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', error.response.data.errors);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    }
  },
};
