import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=6d74dd5a&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=6d74dd5a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d74dd5a",
  null
  
)

/* custom blocks */
import block0 from "@/lang/layout/header.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CWork%5Cmarketing-of-fish-resources%5Csrc%5Ccomponents%5Cglobal%5Clayout%5CHeader.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports