import axios from 'axios'
export default {
  namespaced: true,
  state: {
    myFavorites: null,
    myFavoritesLoading: false,
    myFavoritesError: false,
    ////////////////////////////////
    favorite: null,
    favoriteLoading: false,
    favoriteError: false,
  },
  // get the currant state value
  getters: { 
    myFavoritesData: state => state.myFavorites,
    myFavoritesLoading: state => state.myFavoritesLoading,
    myFavoritesError: state => state.myFavoritesError,
    /////////////////////////////////////////////
    favoriteData: state => state.favorite,
    favoriteLoading: state => state.favoriteLoading,
    favoriteError: state => state.favoriteError

  },
  // use to perform un mutate or change states
  mutations: {
    setMyFavorites (state, data) {
      state.myFavorites = data
    },
    setMyFavoritesLoading (state, loading) {
      state.myFavoritesLoading = loading
    },
    setMyFavoritesError (state, error) {
      state.myFavoritesError = error
    },
    //////////////////////////////////////////
    setFavorite (state, data) {
      state.favorite = data
    },
    setFavoriteLoading (state, loading) {
      state.favoriteLoading = loading
    },
    setFavoriteError (state, error) {
      state.favoriteError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadMyFavorites ({ commit },payload) {
      commit('setMyFavoritesLoading', true)
      try {
        const response = await axios.get('favorite')
        // console.log(response)
        commit('setMyFavorites', response.data)
        commit('setMyFavoritesLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setMyFavoritesLoading', false)
        commit('setMyFavoritesError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async toggleFavorite({ commit }, payload) {
      try {
        commit('setFavoriteLoading', true)
        const response = await axios.get(`favorite/${payload.id}`);
        commit('setFavorite', response.data)
        commit('setFavoriteLoading', false)
        commit('setFavoriteError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setFavoriteLoading', false)
        commit('setFavoriteError',error.response.data)
        return Promise.reject(error.response.data)
      }
    }
  },
};
