// import { beharAxios } from '@/packages/axios/beharAxios'
// export default {
//   namespaced: true,
//   state: {
//     serverResponse: null,
//     serverError: false,
//     serverLoading: false
//   },
//   // get the currant state value
//   getters: {
//     getServerLoading: state => state.serverLoading,
//     getServerResponse: state => state.serverResponse,
//     getServerError: state => state.serverError,
//   },
//   // use to perform un mutate or change states
//   mutations: {
//     SET_FORM_DATA(state, formData) {
//       state.form = formData;
//     },
//     SET_SERVER_RESPONSE(state, response) {
//       state.serverResponse = response;
//     },
//     SET_SERVER_ERROR(state, error) {
//       state.serverError = error;
//     },
//     SET_SERVER_LOADING(state, loading) {
//       state.serverLoading = loading;
//     }
//   },
//   // use to perform un asynchronous tasks
//   actions: {
//     async login({ commit }, state) {
//       try {
//         commit('SET_SERVER_LOADING', true);
//         const response = await beharAxios.post('user/login', {
//           "user_name": "behar-al-fahadi",
//           "password": "123456"
//         });
//         console.log(response)
//         localStorage.setItem("beharToken", response.data.data.token)
//         commit('SET_SERVER_RESPONSE', response.data);
//         commit('SET_SERVER_LOADING', false);
//         commit('SET_SERVER_ERROR', false);
//         // console.log(response)
//         return Promise.resolve(response.data)
//       } catch (error) {
//         // console.log(error)
//         commit('SET_SERVER_ERROR', error);
//         commit('SET_SERVER_LOADING', false);
//         return Promise.reject(error.response.data)
//       }
//     }
//   },
// };













import axios from 'axios'
export default {
  namespaced: true,
  state: {
    serverResponse: null,
    serverError: false,
    serverLoading: false
  },
  // get the currant state value
  getters: {
    getServerLoading: state => state.serverLoading,
    getServerResponse: state => state.serverResponse,
    getServerError: state => state.serverError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    }
  },
  // use to perform un asynchronous tasks
  actions: {
    async login({ commit }) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.get('platforms/behar-market');
        console.log(response)
        const token = response.data.data.token
        localStorage.setItem("beharToken", token)
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR', false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', error);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error)
      }
    }
  },
};
