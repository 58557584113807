function hideEmail(email) {
  let term1 = email.split("@")[0];
  term1 = term1.slice(0, 3) + term1.slice(3).replace(/.(?=..)/g, "*");
  let term2 = email.split("@")[1];
  return `${term1}@${term2}`;
}
/////////////////////////////////////////////////////////////////////////////////
function hidePhone(phone) {
  return (phone = phone.slice(0, 4) + phone.slice(4).replace(/.(?=..)/g, "*"));
}
//////////////////////////////////////////////////////////////////////////////////////
function truncateNumber(num, digits) {
  if (num) {
    num = Math.floor(num * 100) / 100;
    return num.toFixed(digits);
  }
}
function findTranslation(list, value) {
  let item = list.find(item => item.key == value)
  if (item)
    return item[this.locale]
  else
    return value
}
function convertObjectToFormData(obj) {
  let formData = new FormData();
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // if (obj[key]) {
      formData.append(key, obj[key]);
      // }
    }
  }
  return formData;
}
function convertDate(date) {
  date = new Date(date);
  // convert date to "YYYY-MM-DD" format
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth() + 1).toString();
  var dd = date.getDate().toString();
  var mmChars = mm.split("");
  var ddChars = dd.split("");
  return (
    yyyy +
    "-" +
    (mmChars[1] ? mm : "0" + mmChars[0]) +
    "-" +
    (ddChars[1] ? dd : "0" + ddChars[0])
  );
}
module.exports = {
  hideEmail,
  hidePhone,
  truncateNumber,
  findTranslation,
  convertDate,
  convertObjectToFormData,
};
