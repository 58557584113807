
import axios from 'axios'
export default {
  namespaced: true,
  state: {
    subscription: null,
    serverError: false,
    serverLoading: false
  },
  // get the currant state value
  getters: {
    subscription: state => state.subscription,
    serverResponse: state => state.serverResponse,
    serverError: state => state.serverError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_SERVER_RESPONSE(state, response) {
      state.subscription = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    }
  },
  // use to perform un asynchronous tasks
  actions: {
    async subscription({ commit }) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.get('platforms/behar-market/subscription');
        console.log(response)
        commit('SET_SERVER_RESPONSE', response.data.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR', false);
        console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        console.log(error)
        commit('SET_SERVER_ERROR', error);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    }
  },
};
