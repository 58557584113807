// import { beharAxios } from '@/packages/axios/beharAxios'
// export default {
//   namespaced: true,
//   state: {
//     demandStatus: {},
//     demandStatusLoading: false,
//     demandStatusError: false,
//   },
//   // get the currant state value
//   getters: {
//     demandStatus: state => state.demandStatus,
//     demandStatusLoading: state => state.demandStatusLoading,
//     demandStatusError: state => state.demandStatusError,
//   },
//   // use to perform un mutate or change states
//   mutations: {
//     setDemandStatus(state, data) {
//       state.demandStatus = data
//     },
//     setDemandStatusLoading(state, loading) {
//       state.demandStatusLoading = loading
//     },
//     setDemandStatusError(state, error) {
//       state.demandStatusError = error
//     },
//   },
//   // use to perform un asynchronous tasks
//   actions: {
//     async loadDemandStatus({ commit }, payload) {
//       commit('setDemandStatusLoading', true)
//       try {
//         const response = await beharAxios.post('SnD/Status_List', {
//           "client_id": 1,
//           "status_type": "Demands"
//         })
//         console.log(response)
//         commit('setDemandStatus', response.data.data)
//         return Promise.resolve(response)
//       } catch (error) {
//         commit('setDemandStatusError', error.response.data)
//         return Promise.reject(error)
//       }
//       finally {
//         commit('setDemandStatusLoading', false)
//       }
//     },
//   },
// };















import axios from 'axios'
export default {
  namespaced: true,
  state: {
    demandStatus: null,
    demandStatusLoading: false,
    demandStatusError: false,
  },
  // get the currant state value
  getters: {
    demandStatus: state => state.demandStatus,
    demandStatusLoading: state => state.demandStatusLoading,
    demandStatusError: state => state.demandStatusError,
  },
  // use to perform un mutate or change states
  mutations: {
    setDemandStatus(state, data) {
      state.demandStatus = data
    },
    setDemandStatusLoading(state, loading) {
      state.demandStatusLoading = loading
    },
    setDemandStatusError(state, error) {
      state.demandStatusError = error
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    async loadDemandStatus({ commit }, payload) {
      commit('setDemandStatusLoading', true)
      try {
        const response = await axios.get('platforms/behar-market/list/Status_List?status_type=Demands')
        console.log(response)
        commit('setDemandStatus', response.data.data)
        return Promise.resolve(response)
      } catch (error) {
        commit('setDemandStatusError', error.response.data)
        return Promise.reject(error)
      }
      finally {
        commit('setDemandStatusLoading', false)
      }
    },
  },
};
