import axios from 'axios'
export default {
  namespaced: true,
  state: {
    internationalMarket: null,
    internationalMarketLoading: false,
    internationalMarketError: false,
    ////////////////////////////////
    productDetails: null,
    productDetailsLoading: false,
    productDetailsError: false,
  },
  // get the currant state value
  getters: { 
    internationalMarketData: state => state.internationalMarket,
    internationalMarketLoading: state => state.internationalMarketLoading,
    internationalMarketError: state => state.internationalMarketError,
    /////////////////////////////////////////////
    productDetailsData: state => state.productDetails,
    productDetailsLoading: state => state.productDetailsLoading,
    productDetailsError: state => state.productDetailsError,

  },
  // use to perform un mutate or change states
  mutations: {
    setInternationalMarket (state, data) {
      state.internationalMarket = data
    },
    setInternationalMarketLoading (state, loading) {
      state.internationalMarketLoading = loading
    },
    setInternationalMarketError (state, error) {
      state.internationalMarketError = error
    },
    //////////////////////////////////////////
    setProductDetails (state, data) {
      state.productDetails = data
    },
    setProductDetailsLoading (state, loading) {
      state.productDetailsLoading = loading
    },
    setProductDetailsError (state, error) {
      state.productDetailsError = error
    },
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadInternationalMarket ({ commit },payload) {
      commit('setInternationalMarketLoading', true)
      try {
        const response = await axios.get('products/imported',{
          params: payload
        })
        // console.log(response)
        commit('setInternationalMarket', response.data)
        commit('setInternationalMarketLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setInternationalMarketLoading', false)
        commit('setInternationalMarketError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
    ////////////////////////////////////////
    async loadProductDetails({ commit }, payload) {
      try {
        commit('setProductDetailsLoading', true)
        const response = await axios.get(`products/show/${payload.id}`);
        commit('setProductDetails', response.data)
        commit('setProductDetailsLoading', false)
        commit('setProductDetailsError', false)
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('setProductDetailsLoading', false)
        commit('setProductDetailsError',error.response.data)
        return Promise.reject(error.response.data)
      }
    },
  },
};
