import axios from 'axios'
export default {
  namespaced: true,
  state: {
    demandList: [],
    demandListLoading: false,
    demandListError: false,
  },
  // get the currant state value
  getters: {
    demandList: state => state.demandList,
    demandListLoading: state => state.demandListLoading,
    demandListError: state => state.demandListError,
  },
  // use to perform un mutate or change states
  mutations: {
    setDemandList(state, data) {
      state.demandList = data
    },
    setDemandListLoading(state, loading) {
      state.demandListLoading = loading
    },
    setDemandListError(state, error) {
      state.demandListError = error
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    async loadDemand({ commit }, payload) {
      commit('setDemandListLoading', true)
      try {
        const response = await axios.post('platforms/behar-market/supply/list', payload)
        console.log(response)
        commit('setDemandList', response.data.data)
        return Promise.resolve(response)
      } catch (error) {
        commit('setDemandListError', error.response.data)
        return Promise.reject(error)
      }
      finally {
        commit('setDemandListLoading', false)
      }
    },
  },
};
