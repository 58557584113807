
import axios from 'axios'
export default {
  namespaced: true,
  state: {
    listResponse: [],
    listLoading: false,
    listError: null,
    ///////////////////////
    upsertResponse: null,
    upsertLoading: false,
    upsertError: null,
    ///////////////////////
    deleteResponse: null,
    deleteLoading: false,
    deleteError: null,
  },
  // get the currant state value
  getters: {
    listResponse: state => state.listResponse,
    listLoading: state => state.listLoading,
    listError: state => state.listError,
    //////////////////////
    upsertResponse: state => state.upsertResponse,
    upsertLoading: state => state.upsertLoading,
    upsertError: state => state.upsertError,
    ///////////////////////////////////////////////
    //////////////////////
    deleteResponse: state => state.deleteResponse,
    deleteLoading: state => state.deleteLoading,
    deleteError: state => state.deleteError,
    ///////////////////////////////////////////////
  },
  // use to perform un mutate or change states
  mutations: {
    setListResponse(state, data) {
      state.listResponse = data
    },
    setListLoading(state, loading) {
      state.listLoading = loading
    },
    setListError(state, error) {
      state.listError = error
    },
    ////////////////////
    setUpsertResponse(state, data) {
      state.upsertResponse = data
    },
    setUpsertLoading(state, loading) {
      state.upsertLoading = loading
    },
    setUpsertError(state, error) {
      state.upsertError = error
    },
    ////////////////////
    setDeleteResponse(state, data) {
      state.deleteResponse = data
    },
    setDeleteLoading(state, loading) {
      state.deleteLoading = loading
    },
    setDeleteError(state, error) {
      state.deleteError = error
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    setListResponse({ commit }, payload) {
      commit('setListResponse', payload);
    },
    setListError({ commit }, payload) {
      commit('setListError', payload);
    },
    async list({ commit }, payload) {
      commit('setListLoading', true)
      try {
        const response = await axios.post('platforms/behar-market/demand/list', payload);
        console.log(response);
        commit("setListResponse", response.data.data);
        commit('setListError', null)
        return Promise.resolve(response.data.data)
      } catch (error) {
        console.log(error)
        commit('setListError', error.data)
        return Promise.reject(error.data)
      }
      finally {
        commit('setListLoading', false)
      }
    },
    async upsert({ commit }, form) {
      commit('setUpsertLoading', true)
      try {
        const response = await axios.post('platforms/behar-market/demand/add', form);
        console.log(response);
        commit('setUpsertResponse', response.data)
        commit('setUpsertError', null)
        return Promise.resolve(response)
      } catch (error) {
        console.log(error)
        commit('setUpsertError', error.data)
        return Promise.reject(error)
      }
      finally {
        commit('setUpsertLoading', false)
      }
    },
    async delete({ commit }, form) {
      commit('setDeleteLoading', true)
      try {
        const response = await axios.post('platforms/behar-market/demand/update/remove', form);
        console.log(response);
        commit('setDeleteResponse', response.data)
        commit('setDeleteError', null)
        return Promise.resolve(response)
      } catch (error) {
        console.log(error)
        commit('setDeleteError', error.data)
        return Promise.reject(error)
      }
      finally {
        commit('setDeleteLoading', false)
      }
    },
  },
};;


