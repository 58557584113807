<template>
  <div class="intro" v-if="sections">
    <div class="row">
      <div class="col-lg-8 col-xl-6">
        <div class="intro-wrapper">
          <div class="intro">
              <h1 class="fs-2 mb-5">
                <span>{{ $t('heading')+' '}}</span>
                <span class="custom-title-circle d-inline-block d-md-block d-lg-inline-block">{{$t('headingHighlight')}}</span>
              </h1>
              <p class="fs-6">{{ $t('description') }}</p>
          </div>
      </div>
    </div>
    </div>
    <div class="categories row my-5">
      <div class="col-lg-4 my-2" v-for="record in records" :key="record.id">
        <div class="category h-100 w-100 d-flex justify-content-between align-items-center" 
            :class="{'custom-secondary-bg':record.id==2}">
            <div class="details">
              <h4>{{ record[locale].title }}</h4>
              <h6 class="text-muted">{{record.value}} <small v-if="record.id==2">{{$t('weight') }}</small> {{record[locale].subTitle}}</h6>
            </div>
            <div class="icon-wrapper">
              <img :src="record.icon" alt="">
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<i18n src="@/lang/global/global.json"></i18n>
<i18n src="@/lang/home/intro.json"></i18n>

<script>
export default {
  name: 'Intro',

  data() {
    return {
      
    };
  },
  computed:{
    mainStaticData () {
      return this.$store.getters['mainStaticData/mainStaticData']
    },
    sections () {
      if(this.mainStaticData)
        return this.$store.getters['mainStaticData/mainStaticData']["data"]["sections"]
      else return false
    },

    records(){
      return [
        {
          en:{
            title:"Local market",
            subTitle:"kind of fish"
          },
          ar:{
            title:"السوق المحلي",
            subTitle:"نوع من السمك"
          },
          icon:require('@/assets/home/fish1.svg'),
          value:this.sections.loacl,
          id:1
        },
        {
          en:{
            title:"Auctions",
            subTitle:"Total Quantity Offered"
          },
          ar:{
            title:"المزادات",
            subTitle:"إجمالي كمية المعروض"
          },
          icon:require('@/assets/home/fish2.svg'),
          value:this.sections.auction,
          id:2
        },
        {
          en:{
            title:"International market",
            subTitle:"kind of fish"
          },
          ar:{
            title:"السوق الدولي",
            subTitle:"نوع من السمك"
          },
          icon:require('@/assets/home/fish3.svg'),
          value:this.sections.international,
          id:3
        },
    ]
  },
},
  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.intro{
  position: relative;
  // z-index: 0;
}
  .intro-wrapper{
    margin-top:1rem;
    color: white;
    h1{
      line-height: 2;
      .custom-title-circle{
        padding: 0.5rem 1.5rem;
        border: 3px solid $primary;
        border-radius: 50%;
        width: fit-content;
      }
    }
    p{
      line-height: 2;
    }
  }
  .categories{
    .category{
      background-color: white;
      color: #000000;
      border-radius: 1rem;
      padding: 1rem;
      box-shadow: 1px 1px 4px rgba(0,0,0,0.25);
    }
    .custom-secondary-bg{
      background-color: $secondary;
      color: white;
    }
  }
</style>