<template>
  <div class="best-seller">
      <div class="row my-5">
        <div class="d-none d-md-block col-xl-4"></div>
        <div class="col-md-6 col-xl-4 my-4">
          <div class="heading text-center">
            <h4 class="mb-4">
              <span>{{ $t('heading')+' '}}</span> 
            </h4>
            <h6>
              <span class="custom-title-circle">{{ $t('headingHighlight') }}</span>
            </h6>
          </div>
        </div>
        <div class="col-md-6 col-xl-4">
          <div class="market-filter h-100 d-flex align-items-center justify-content-end">
            <div class="market-wrapper d-flex align-items-center gap-2">
                <label for="market">{{ $t('market.label') }}</label>
                <Dropdown v-model="selectedMarket" :options="markets" optionLabel="name" optionValue="id" id="market" :placeholder="$t('market.placeholder')" class="market-dorpdown" />
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5" v-if="products.length>0">
        <div class="col-lg-6 col-xxl-4 my-2" v-for="product in products" :key="product.id">
          <div class="item-wrapper">
            <div class="row">
              <div class="col-12">
                <div class="item-img">
                  <img :src="mediaURL+'fish_types/'+product.image" alt="" @click="buy(product)">
                  <!-- <img src="@/assets/home/best-seller-img.png" alt=""> -->
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <div class="item-name h-100 d-flex flex-column justify-content-center align-items-start">
                  <h4 class="heading-underline" :class="[locale=='ar'?'rtl-underline':'ltr-underline']" 
                  @click="buy(product)">
                    {{ product.name}}</h4>
                </div>
              </div>
              <div class="col-12">
                <ul class="label-stat">
                  <li>
                    <div class="d-flex justify-content-between align-items-center label-stat-item">
                      <p class="m-0 py-2">{{$t('product.availableQuantity') }}</p>
                      <p class="m-0 py-2"><b>{{ product.quantity_available}} <small>{{ $t('weight') }}</small></b></p>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between align-items-center label-stat-item">
                      <p class="m-0 py-2">{{$t('product.averagePrice') }}</p>
                      <p class="m-0 py-2"><b>{{truncateNumber(product.average_price,2)}} <small>{{ $t('currency') }}</small></b></p>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between align-items-center label-stat-item">
                      <p class="m-0 py-2">{{$t('product.quantitySold') }}</p>
                      <p class="m-0 py-2"><b>{{ product.quantity_sold}} <small>{{ $t('weight') }}</small></b></p>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex justify-content-between align-items-center label-stat-item">
                      <p class="m-0 py-2">{{$t('product.totalSales') }}</p>
                      <p class="m-0 py-2"><b>{{ product.total_sales}} <small>{{ $t('currency') }}</small></b></p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-12">
                <div class="item-action d-flex align-items-center justify-content-center mt-2">
                  <div>
                    <Button :label="$t('buy')" class="px-5" @click="buy(product)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 my-4">
          <Paginator
              :first.sync="pagination.offset"
              :rows.sync="pagination.rows"
              :totalRecords="totalRecords"
              :rowsPerPageOptions="pagination.rowsOptions"
              @page="onPage($event)"
            ></Paginator>
        </div>
      </div>
      <div class="no-results text-center" v-else>
        <div class="img-wrapper">
          <img src="@/assets/global/no-results.svg" alt="">
        </div>
        <h4>{{ $t('noResultsMsg') }}</h4>
      </div>
  </div>
</template>
<i18n src="@/lang/home/bestSeller.json"></i18n>
<i18n src="@/lang/global/global.json"></i18n>
<script>
///////////////////////////////////
import { cloneDeep } from "lodash";
///////////////////////////////////
import { truncateNumber } from "@/helpers/utilities";
import pagination from "@/mixins/options/pagination.js";
export default {
  name: "BestSeller",
  mixins: [pagination],
  data() {
    return {
      selectedMarket: null
    };
  },
  computed: {
    mediaURL() {
      return this.$store.getters["urls/mediaURL"];
    },
    mainStaticData() {
      return this.$store.getters["mainStaticData/mainStaticData"];
    },
    markets() {
      let markets = [];
      if (this.mainStaticData) {
        markets = cloneDeep(this.mainStaticData["data"]["markets"]);
        // console.log(markets)
        let allOption = { ar: "الكل", en: "All", id: "" };
        // Check if an object with id=null already exists in the array
        if (!markets.find(item => item.id === "")) {
          markets.unshift({ name: allOption[this.locale], id: "" });
        }
      }
      return markets;
    },
    // markets () {
    //   if(this.mainStaticData)
    //     return this.$store.getters['mainStaticData/mainStaticData']["data"]["markets"]
    //   else return []
    // },
    primaryMarket() {
      const primaryMarket = this.markets.find(market => market.primary === 1);
      return primaryMarket ? primaryMarket.id : null;
    },

    //////////////////////////////////////

    trafficDataLoading() {
      return this.$store.getters["bestSeller/trafficDataLoading"];
    },
    trafficDataError() {
      return this.$store.getters["bestSeller/trafficDataError"];
    },
    trafficData() {
      return this.$store.getters["bestSeller/trafficData"];
    },
    products() {
      if (this.trafficData) return this.trafficData["data"]["data"];
      else return [];
    },
    totalRecords() {
      if (this.trafficData) return this.trafficData["data"]["total"];
      else return 0;
    },
    filterParams() {
      return {
        market_id: this.selectedMarket,
        page: this.pagination.pageNumber + 1,
        limit: this.pagination.rows
      };
    }
  },
  watch: {
    selectedMarket() {
      setTimeout(() => this.loadData(), 250);
    }
  },
  mounted() {
    this.loadData();
    if (this.primaryMarket) this.selectedMarket = this.primaryMarket;
  },

  methods: {
    truncateNumber,
    async loadData() {
      try {
        await this.$store.dispatch(
          "bestSeller/loadTrafficData",
          this.filterParams
        );
        // Do something with the loaded data
      } catch (error) {
        console.error(error);
      }
    },
    buy(product) {
      this.$router.push({
        name: "localMarket",
        query: { fishId: product.id }
      });
    },
    onPage(event) {
      this.pagination.pageNumber = event.page;
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
.best-seller {
  .custom-title-circle {
    padding: 0.5rem 0.75rem;
    border: 3px solid $primary;
    border-radius: 50%;
  }
  .item-wrapper {
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
    background-color: white;
    &:hover {
      box-shadow: rgba($primary, 0.1) 0px 8px 24px;
      transform: translateY(10px);
    }
    transition: all 0.5s ease;
    .item-img {
      height: 10rem;
      cursor: pointer;
      img {
        border-radius: 1rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .label-stat {
      margin: 0;
      padding: 0;
      list-style: none;
      .label-stat-item {
        border-bottom: 1px solid #dddddd;
      }
    }

    .item-name {
      .heading-underline {
        position: relative;
        display: inline-block;
        margin-bottom: 1rem; /* Add margin to create space between the heading and underline */
        color: $secondary;
        &:hover {
          color: $primary;
          cursor: pointer;
        }
      }
      .heading-underline::after {
        content: "";
        position: absolute;
        bottom: -5px; /* Position the underline just below the text */
        width: 2rem; /* Set the width of the underline */
        height: 3px; /* Set the height of the underline */
        background-color: $secondary; /* Set the color of the underline */
      }
      .heading-underline.ltr-underline::after {
        right: auto;
        left: 0;
      }
      .heading-underline.rtl-underline::after {
        right: 0;
        left: auto;
      }
    }
    .table {
      td {
        color: $secondary;
        padding: 5px;
      }
      .highlight-value {
        font-weight: bold;
      }
    }
  }
}
.market-filter {
  .market-wrapper {
    border: 1px solid $gray;
    padding: 2px 8px;
    border-radius: 4px;
    label {
      background-color: $secondary;
      color: white;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
}
::v-deep(.market-dorpdown) {
  border: none;
  min-width: 200px;
}
</style>