<template>
    <div class="cart">
      <h5 class="cart__heading text-center mb-4">{{$t('Your Shopping Cart')}}</h5>
      <CartItem v-for="item in items" :key="item.id" :item="item" />
      <div class="cart__total d-flex justify-content-between p-2">
        <p class="cart__total-title">Subtotal</p>
        <p class="cart__total-price text-success fw-bold">{{subtotal}} {{$t('currency')}}</p>
      </div>
      <div class="cart__footer">
        <Button :label="$t('Order Now')" icon="pi pi-check" class="p-button-sm p-button-success" @click.prevent="makeOrder"/>
        <Button :label="$t('Empty Your Cart')" icon="pi pi-trash" class="p-button-sm p-button-danger my-2" @click.prevent="emptyTheCart"/>
      </div>
    </div>
</template>
<i18n src="@/lang/global/global.json"></i18n>

<script>
import CartItem from "./CartItem";
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  components: {
    CartItem
  },
  computed: {
    subtotal() {
      let subtotal = 0;
      for (let item of this.items) {
        subtotal += item.price * item.cart_quantity;
      }
      return subtotal;
    }
  },
  methods: {
    makeOrder() {
      this.$router.push({ name: "cart" });
    },
    emptyTheCart() {
      let cart = [];
      localStorage.setItem("cart", JSON.stringify(cart));
      this.$store.dispatch("cart/setCart", cart);
      this.$toast.add({
        severity: "warn",
        summary: "Shpping Cart",
        detail: "Products deleted from Cart Successfully",
        life: 3000
      });
    }
  }
};
</script>