export default {
  namespaced: true,
  state: {
    baseURL: "https://uat-clientapi.behar.market/api/",
    mediaURL: "https://uat-clientapi.behar.market/media/",
  },
  // get the currant state value
  getters: {
    mediaURL: state => state.mediaURL,
    baseURL: state => state.baseURL,
  },
  // use to perform un mutate or change states
  mutations: {

  },
  // use to perform un asynchronous tasks
  actions: {

  },
};
