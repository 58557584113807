// import { beharAxios } from '@/packages/axios/beharAxios'
// export default {
//   namespaced: true,
//   state: {
//     sizes: [],
//     sizesLoading: false,
//     sizesError: false,
//   },
//   // get the currant state value
//   getters: {
//     sizes: state => state.sizes,
//     sizesLoading: state => state.sizesLoading,
//     sizesError: state => state.sizesError,
//   },
//   // use to perform un mutate or change states
//   mutations: {
//     setSizes(state, data) {
//       state.sizes = data
//     },
//     setSizesLoading(state, loading) {
//       state.sizesLoading = loading
//     },
//     setSizesError(state, error) {
//       state.sizesError = error
//     },
//   },
//   // use to perform un asynchronous tasks
//   actions: {
//     async loadSizes({ commit }, payload) {
//       commit('setSizesLoading', true)
//       try {
//         const response = await beharAxios.post('SnD/Sizes', {
//           "client_id": 1
//         })
//         console.log(response)
//         commit('setSizes', response.data.data)
//         return Promise.resolve(response)
//       } catch (error) {
//         commit('setSizesError', error)
//         return Promise.reject(error)
//       }
//       finally {
//         commit('setSizesLoading', false)
//       }
//     },
//   },
// };


















import axios from 'axios'
export default {
  namespaced: true,
  state: {
    sizes: [],
    sizesLoading: false,
    sizesError: false,
  },
  // get the currant state value
  getters: {
    sizes: state => state.sizes,
    sizesLoading: state => state.sizesLoading,
    sizesError: state => state.sizesError,
  },
  // use to perform un mutate or change states
  mutations: {
    setSizes(state, data) {
      state.sizes = data
    },
    setSizesLoading(state, loading) {
      state.sizesLoading = loading
    },
    setSizesError(state, error) {
      state.sizesError = error
    },
  },
  // use to perform un asynchronous tasks
  actions: {
    async loadSizes({ commit }, payload) {
      commit('setSizesLoading', true)
      try {
        const response = await axios.get('platforms/behar-market/list/Sizes')
        console.log(response)
        commit('setSizes', response.data.data)
        return Promise.resolve(response)
      } catch (error) {
        commit('setSizesError', error)
        return Promise.reject(error)
      }
      finally {
        commit('setSizesLoading', false)
      }
    },
  },
};
