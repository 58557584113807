import axios from 'axios'
export default {
  namespaced: true,
  state: {
    mainStaticData: null,
    mainStaticDataLoading: false,
    mainStaticDataError: false
  },
  // get the currant state value
  getters: { 
    mainStaticData: state => state.mainStaticData,
    mainStaticDataLoading: state => state.mainStaticDataLoading,
    mainStaticDataError: state => state.mainStaticDataError
  },
  // use to perform un mutate or change states
  mutations: {
    setMainStaticData (state, data) {
      state.mainStaticData = data
    },
    setMainStaticDataLoading (state, loading) {
      state.mainStaticDataLoading = loading
    },
    setMainStaticDataError (state, error) {
      state.mainStaticDataError = error
    }
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadMainStaticData ({ commit }) {
      commit('setMainStaticDataLoading', true)
      try {
        const response = await axios.get('main')
        commit('setMainStaticData', response.data)
        commit('setMainStaticDataLoading', false)
        return Promise.resolve(response.data)
      } catch (error) {
        commit('setMainStaticDataError',true)
        commit('setMainStaticDataLoading', false)
        return Promise.reject(error.response.data)
      }
    }
  },
};
